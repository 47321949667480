import {
  TOGGLE_EDIT_UI,
  SET_EDIT_UI,
  SET_EDITOR_SNACKBAR_VISIBILITY,
  SET_EDITOR_SNACKBAR_MSG,
  SET_MENU_SNACKBAR,
  SET_DRAGGING_STATE,
  ADJUST_DIRT_SCORE,
  TOGGLE_TRASHED_ENTITIES,
} from '@constants/mutations'

export const state = {
  autosave: false,
  isShowingEditUI: false,
  isEditorSnackbarVisible: false,
  editorSnackbarMessage: '',
  isMenuSnackbarVisible: true,
  dirtScore: 0,
  isShowingTrashedItems: false,
  isDragging: false,
}

export const mutations = {
  [TOGGLE_EDIT_UI](state) {
    state.isShowingEditUI = !state.isShowingEditUI
  }, // TOGGLE_EDIT_UI

  [SET_EDIT_UI](state, visibility) {
    state.isShowingEditUI = visibility
  }, // SET_EDIT_UI

  [SET_EDITOR_SNACKBAR_VISIBILITY](state, visibility) {
    state.isEditorSnackbarVisible = visibility
  }, // SET_EDITOR_SNACKBAR_VISIBILITY

  [SET_EDITOR_SNACKBAR_MSG](state, msg) {
    state.editorSnackbarMessage = msg
  }, // SET_EDITOR_SNACKBAR_MSG

  [SET_MENU_SNACKBAR](state, isVisible) {
    state.isMenuSnackbarVisible = isVisible
  }, // SET_MENU_SNACKBAR

  [ADJUST_DIRT_SCORE](state, adjustment) {
    state.dirtScore += adjustment ? 1 : -1
  }, // ADJUST_DIRT_SCORE

  [TOGGLE_TRASHED_ENTITIES](state) {
    state.isShowingTrashedItems = !state.isShowingTrashedItems
  }, // TOGGLE_TRASHED_ENTITIES

  [SET_DRAGGING_STATE](state, isDragging) {
    state.isDragging = isDragging
  }, // SET_DRAGGING_STATE
} // mutations

export const getters = {
  isShowingEditUI: (state, getters, rootState, rootGetters) =>
    state.isShowingEditUI && rootGetters['auth/loggedIn'],
  isAutosaveEnabled: (state) => !!state.autosave,
  isEditorSnackbarVisible: (state) => !!state.isEditorSnackbarVisible,
  isDragging: (state) => state.isDragging,
  editorSnackbarMessage: (state) => state.editorSnackbarMessage,

  isMenuDirty: (state) => state.dirtScore > 0,

  isMenuSnackbarVisible: (state, getters) =>
    state.isMenuSnackbarVisible &&
    (!!getters.isMenuDirty || getters.dirtySections.length),

  isShowingTrashedItems: (state) => state.isShowingTrashedItems,

  dirtyMenus: (state, _, __, rootGetters) => {
    return rootGetters['menus/getDirtyMenus']
  },
  dirtySections: (state, _, __, rootGetters) => {
    return rootGetters['sections/getDirtySections']
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, getters }) {},

  toggleEditUI({ commit, getters, dispatch }) {
    commit(SET_EDITOR_SNACKBAR_VISIBILITY, true)
    commit(TOGGLE_EDIT_UI)
    commit(
      SET_EDITOR_SNACKBAR_MSG,
      `Extra Controls are now ${getters.isShowingEditUI ? 'ON' : 'OFF'}`
    )
    dispatch('showMenuSnackbar')
  }, // toggleEditUI

  setEditUI({ commit }, visibility) {
    commit(SET_EDITOR_SNACKBAR_VISIBILITY, visibility)
    commit(SET_EDIT_UI, visibility)
  }, // setEditUI

  setEditorSnackbarVisibility({ commit }, visibility) {
    commit(SET_EDITOR_SNACKBAR_VISIBILITY, visibility)
  }, // setEditorSnackbarVisibility

  setEditorSnackbarMsg({ commit }, msg) {
    commit(SET_EDITOR_SNACKBAR_MSG, msg)
  }, // setEditorSnackbarMsg

  showMenuSnackbar({ commit }) {
    commit(SET_MENU_SNACKBAR, true)
  }, // showMenuSnackbar

  hideMenuSnackbar({ commit }) {
    commit(SET_MENU_SNACKBAR, false)
  }, // hideMenuSnackbar

  adjustEditorDirtScore({ commit }, adjustment) {
    commit(ADJUST_DIRT_SCORE, adjustment)
  }, // adjustEditorDirtScore

  toggleTrashedEntities({ commit }) {
    commit(TOGGLE_TRASHED_ENTITIES)
  }, // toggleTrashedEntities

  setDraggingState({ commit }, isDragging) {
    commit(SET_DRAGGING_STATE, isDragging)
  }, // setDraggingState
} // actions
