<template>
  <BasePageSection class="page--header" full-bleed-bg>
    <VLayout row>
      <VFlex xs12>
        <img
          v-if="bgImage.length"
          :src="bgImage"
          :alt="`${$route.title} Header Image`"
        />
        <BasePageContent
          v-model="title"
          content-key="header_title"
          tag-class="page-title header-1 grey--text mt-3 d-inline-block"
          tag="h2"
        />
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    bgImage: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
