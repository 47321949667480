<template>
  <component
    :is="component"
    v-bind="allPropsAndAttrs"
    @click="showEmailModal"
    v-on="$listeners"
    >{{ ['lg', 'xl'].includes($mq) ? longText : shortText }}
  </component>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ButtonSendMessage',
  props: {
    outlineColor: {
      type: String,
      default: '#FFF',
    },
    component: {
      type: String,
      default: 'BaseButton',
    },
    lineWeight: {
      type: String,
      default: '2px',
    },
    longText: {
      type: String,
      default: 'Send us a Message',
    },
    shortText: {
      type: String,
      default: 'Message Us',
    },
  },
  data() {
    return {}
  },
  computed: {
    allPropsAndAttrs() {
      let defaults = {
        color: 'transparent',
      }
      return {
        ...defaults,
        ...this.$props,
        ...this.$attrs,
      }
    }, // allPropsAndAttrs
  },
  methods: {
    ...mapActions('nav', ['showEmailModal']),
  },
}
</script>
