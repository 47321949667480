<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WithItem',
  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('items', ['getItem']),
    theItem() {
      return this.getItem(this.id)
    }, // theItem
  },
  render() {
    return this.$scopedSlots.default({
      theItem: this.theItem,
    })
  },
}
</script>
