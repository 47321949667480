<script>
import { mapGetters } from 'vuex'
import _sortBy from 'lodash/sortBy'

export default {
  name: 'WithLocationSummaries',
  props: {
    filter: {
      type: [Function, Boolean],
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getLocationSummary']),
    filteredLocs() {
      let summaries = this.getLocationSummary
      if (this.filter) {
        summaries = summaries.filter(this.filter)
      }
      return _sortBy(summaries, 'order')
    }, // filteredLocs
  },
  render() {
    return this.$scopedSlots.default({
      locations: this.filteredLocs,
    })
  },
}
</script>
