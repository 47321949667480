<template>
  <BaseContent
    v-bind="{ ...$attrs, ...$props }"
    :contentable-id="getThePageId"
    contentable-type="page"
    :class="contentClass"
    :content="content"
    @saveContent="saveContent"
    @saveDelete="saveDelete"
    v-on="$listeners"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageContent',
  props: {
    contentKey: {
      type: String,
      required: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
    defaultContent: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters('pages', ['getThePageId', 'getPageContent']),
    content() {
      let pageContent = this.getPageContent(this.contentKey)
      return typeof pageContent === 'undefined'
        ? this.defaultContent
        : pageContent
    },
  },
  methods: {
    ...mapActions('pages', ['updatePageContent']),
    async saveContent(value) {
      await this.updatePageContent({
        pageId: this.getThePageId,
        key: this.contentKey,
        value,
      })
    }, // saveContent
    async saveDelete() {
      await this.updatePageContent({
        pageId: this.getThePageId,
        key: this.contentKey,
        value: null,
      })
    }, // saveDelete
  }, // methods
}
</script>
