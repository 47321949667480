<template>
  <VSheet v-if="bannerState" class="global-banner" tile :color="bannerColor">
    <VContainer grid-list-xl>
      <VLayout row>
        <VFlex :style="contentStyles">
          <slot />
        </VFlex>
        <VFlex class="text-xs-right" shrink>
          <slot name="actions" />
          <BaseButtonOutline
            :outline-color="closeBtnColor"
            :text-color="closeBtnTextColor"
            outline
            small
            @click="bannerState = false"
            >Close</BaseButtonOutline
          >
        </VFlex>
      </VLayout>
    </VContainer>
  </VSheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BaseBanner',
  components: {},
  props: {
    bannerColor: {
      type: String,
      required: false,
      default: 'white',
    },
    bannerTextColor: {
      type: String,
      required: false,
      default: 'inherit',
    },
    closeBtnColor: {
      type: String,
      required: false,
      default: 'blue darken-3',
    },
    closeBtnTextColor: {
      type: String,
      required: false,
      default: 'white',
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('nav', ['getGlobalBannerState']),
    bannerState: {
      get() {
        return this.getGlobalBannerState
      },
      set(val) {
        this.setGlobalBanner(val)
      },
    },
    contentStyles() {
      return {
        color: this.bannerTextColor,
      }
    },
  },
  methods: {
    ...mapActions('nav', ['setGlobalBanner']),
  },
}
</script>

<style lang="scss">
.theme--light.global-banner {
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
}
</style>
