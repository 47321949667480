<template>
  <VDialog
    :value="isAuthModalVisible"
    :max-width="500"
    class="auth-modal"
    @update:returnValue="hideAuthModal"
  >
    <VCard>
      <VCardTitle class="orange lighten-2 white--text">
        <VFlex xs12 mb-2 text-xs-center>
          <h2 class="header-3 mb-1">{{ getSiteTitle }}</h2>
          <h3 class="r-title">Login</h3>
        </VFlex>
      </VCardTitle>
      <VCardText class="font-weight-medium pa-4">
        <AuthForm show-dismiss @dismiss="hideAuthModal" />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'
import AuthForm from '@auth/AuthForm'

export default {
  name: 'AuthModal',
  components: {
    VDialog,
    VCardText,
    AuthForm,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('auth', ['isAuthModalVisible']),
    ...mapGetters('site', ['getSiteTitle']),
  },
  methods: {
    ...mapActions('auth', ['hideAuthModal']),
  },
}
</script>

<style lang="css">
.auth-modal {
  z-index: 930;
}
</style>
