/**
 * This exports a service that's customized specifically for
 * the Resto API.
 */

import { RESTO_API_BASE_URL } from '../constants/credentials'
import store from '@store/store'
import axios from 'axios'

// eslint-disable-next-line new-cap
const api = new axios.create({
  baseURL: RESTO_API_BASE_URL,
  timeout: 10000,
  headers: {},
})
api.interceptors.response.use(
  function(response) {
    // Do something with response data
    // console.log('Intercepted api response', { response })
    return response
  },
  function(error) {
    // Do something with response error
    // console.log({ error })
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logOut')
      store.dispatch('auth/showAuthModal')
      store.dispatch('auth/showAuthSnackbar')
    } else if (error.response && error.response.status === 404) {
      return Promise.reject(error.request)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request)
      Promise.reject(error.request)
    }
    return Promise.reject(error)
  }
)

api.createAndAttachEntity = ({
  newEntity,
  entityRoute,
  entityId,
  childEntityRoute,
}) => {
  let route = `${entityRoute}/${entityId}/${childEntityRoute}`
  // eslint-disable-next-line
  console.log(`++Creating new attachment and attaching!! Route : ${route}`)
  return api.post(route, newEntity)
} // createAndAttachEntity

api.createEntity = (newEntity) => {
  // eslint-disable-next-line
  console.log(`++Creating new entity!! Route : ${newEntity.createRoute}`)
  return api.post(newEntity.createRoute, newEntity)
} // createEntity

api.deleteEntity = (entityName, entityId) => {
  // eslint-disable-next-line
  console.log(`-- Deleting entity!! Route : ${entityName}/${entityId}`)
  return api.delete(`${entityName}/${entityId}`)
} // deleteEntity

api.attachForeignEntity = ({
  entityName,
  entityId,
  relatedEntity,
  relatedId,
}) => {
  let route = `${entityName}/${entityId}/${relatedEntity}/${relatedId}`
  // eslint-disable-next-line
  console.log(`++Attaching!! Route : ${route}`)
  return api.post(route)
} // attachForeignEntity

api.detachForeignEntity = ({
  entityName,
  entityId,
  relatedEntity,
  relatedId,
}) => {
  let route = `${entityName}/${entityId}/${relatedEntity}/${relatedId}`
  // eslint-disable-next-line
  console.log(`--Detaching!! Route : ${route}`)
  return api.delete(route)
} // detachForeignEntity

api.updateMenu = (menu) => {
  if (!menu.id) {
    return false
  }
  return api.patch(`menus/${menu.id}`, menu)
}

api.updateSection = (section) => {
  if (!section.id) {
    return false
  }
  return api.patch(`menu-sections/${section.id}`, section)
}

export default api

async function quickFetch(endpoint) {
  const response = await api(endpoint)
  return response
}

export function makeService(endpoint) {
  return {
    async find({ id }) {
      return quickFetch(`${endpoint}/${id}`)
    },
    async list() {
      return quickFetch(endpoint)
    },
  }
}
