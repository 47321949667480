<template>
  <BaseContent
    v-bind="{ ...$attrs, ...$props }"
    :contentable-id="getTheLocationId"
    contentable-type="location"
    :class="contentClass"
    :content="content"
    @saveContent="saveContent"
    @saveDelete="saveDelete"
    v-on="$listeners"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LocationContent',
  props: {
    contentKey: {
      type: String,
      required: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
    defaultContent: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('locations', ['getTheLocationId', 'getTheLocationContent']),
    content() {
      let locContent = this.getTheLocationContent(this.contentKey)
      return typeof locContent === 'undefined'
        ? this.defaultContent
        : locContent
    },
  },
  methods: {
    ...mapActions('locations', ['updateLocationContent']),
    async saveContent(value) {
      await this.updateLocationContent({
        locationId: this.getTheLocationId,
        key: this.contentKey,
        value,
      })
    }, // saveContent
    async saveDelete() {
      await this.updateLocationContent({
        locationId: this.getTheLocationId,
        key: this.contentKey,
        value: null,
      })
    }, // saveDelete
  },
}
</script>
