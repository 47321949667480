<template>
  <Layout>
    <BasePageSection bg-color="#fdf9ec">
      <VLayout justify-center>
        <VFlex xs6>
          <h1 class="header-4">Not Found</h1>
          <h3 class="r-title"
            >Sorry, but we couldn't find that page. Head back to our
            <VBtn flat small to="/"> main page </VBtn> or
            <VBtn flat small color="green darken-2" to="/cotnact"
              >get in touch</VBtn
            >
            if you need assistance.</h3
          >
        </VFlex>
      </VLayout>
    </BasePageSection>
  </Layout>
</template>

<script>
import Layout from '@utils/get-layout'
export default {
  page: {
    title: 'Page Not Found',
    meta: [{ name: 'description', content: 'Not found' }],
  },
  components: {
    Layout,
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
}
</script>
