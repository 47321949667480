import { SET_ENTITIES } from '@constants/mutations'
import { ENTITY_NAMES } from '@constants/lookupTables'

export const state = {
  entities: {},
}

export const mutations = {
  [SET_ENTITIES](state, entities) {
    state.entities = entities
  },
}

export const getters = {
  getCreateRoute: (_, __, ___, rootGetters) => (itemId) => {
    let getRouteFn = rootGetters['menus/getObjRouteFromEntity']
    let itemsRoute = getRouteFn(ENTITY_NAMES.ITEMS)
    let attrsRoute = getRouteFn(ENTITY_NAMES.ATTRIBUTES)
    return `${itemsRoute}/${itemId}/${attrsRoute}`
  }, // getCreateRoute

  getAttr: (state) => (id) => state.entities[id] || null,

  getAttrs: (state) => (ids) =>
    !ids ? [] : ids.map((id) => state.entities[id]), // getAttrs
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, getters }) {},
} // actions
