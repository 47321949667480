<template>
  <VCard v-if="!element.id" class="drag-handle pa-2 elevation-0">
    Drag me to the display
  </VCard>
  <div v-else>
    <Portal v-if="showingEditUI" to="layout-editor-header">
      <VCard class="layout-text-size-adjuster teal lighten-2 pa-3">
        <a
          href="#"
          class="layout-text-size-adjuster-close"
          @click.prevent="turnOffEditing"
          >&times;</a
        >
        <input v-model="size" type="range" min=".5" max="2" step="0.05" />
        <VSelect v-model="tag" :items="['div', 'h2', 'h1']" />
      </VCard>
    </Portal>
    <div
      :class="['drag-handle force-ems text-elem', { editing: showingEditUI }]"
      :style="{ 'font-size': `${myFontSize}em` }"
      @click.prevent="isEditing = true"
    >
      <BaseEditable
        :content="text"
        :tag="tagWithClasses"
        :class="element.content.class"
        @update="updatedText"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import TagClassMixin from '@mixins/tag-class'

export default {
  name: 'LayoutElementTypeText',
  mixins: [clickaway, TagClassMixin],
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {},
    },
    ratio: {
      type: Number,
      default: 1,
    },
    fontSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isEditing: false,
      size: this.element.content.size || 1,
      text: this.element.content.text || null,
      debounceTimer: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('layoutEditor', ['isShowingLayoutEditUI']),
    myFontSize() {
      return this.fontSize * (this.size / 10) * this.ratio
    },
    showingEditUI() {
      return this.isEditing && this.isShowingLayoutEditUI
    },
  },
  watch: {
    size() {
      if (this.debounceTimer) {
        return
      }
      this.debounceTimer = setTimeout(this.updateTextElement, 1000)
    },
    text() {
      if (this.debounceTimer) {
        return
      }
      this.debounceTimer = setTimeout(this.updateTextElement, 1000)
    },
    tag() {
      if (this.debounceTimer) {
        return
      }
      this.debounceTimer = setTimeout(this.updateTextElement, 1000)
    },
  },
  methods: {
    ...mapActions('layouts', ['updateElement']),
    turnOffEditing() {
      this.isEditing = false
    },
    updatedText(text) {
      this.text = text
    },
    async updateTextElement() {
      let element = {
        id: this.element.id,
        type: 'text',
        title: this.text,
        content: {
          size: this.size,
          text: this.text,
          tag: this.tag,
          class: this.element.content.class,
        },
      }
      let newEl = await this.updateElement(element)
      this.debounceTimer = null
      this.text = newEl.content.text
    },
  },
}
</script>

<style lang="scss" scoped>
.layout-text-size-adjuster {
  position: relative;
}
.layout-text-size-adjuster-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  color: white;
  text-decoration: none !important;
}
.text-elem {
  padding: 0.2em 0.4em;
  &.editing {
    position: relative;
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: #f2784f;
      transform: translateY(100%);
    }
  }
}
</style>
