<template>
  <div class="drag-handle force-ems" :style="`font-size:${defaultFontSize}`">
    <WithMenuSection
      v-if="isSection && !isSummaryView"
      :id="relatedId"
      v-slot="{
        section,
        isSectionLoading,
        isHovering,
        myMods,
        itemMods,
        itemsToSave,
      }"
    >
      <BaseLoader v-if="isSectionLoading" />
      <BaseMenuComponent
        v-else-if="!!section"
        :entity="section"
        :my-mods="myMods"
        :item-mods="itemMods"
        :items-to-save="itemsToSave"
        name="MenuSection"
      />
      <div v-else>Section Not Found</div>
    </WithMenuSection>
    <VCard v-else class="pa-2 elevation-0 mb-2 align-items-center">
      <VIcon v-if="icon" left>{{ icon }}</VIcon>
      <span>{{ text }}</span>
      <span v-if="isSection" class="ml-2">({{ numItems }})</span>
    </VCard>
  </div>
</template>

<script>
import WithMenuSection from '@dataProviders/WithMenuSection'
import _get from 'lodash/get'
export default {
  name: 'LayoutElementTypeRelated',
  components: { WithMenuSection },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {},
    },
    fontSize: {
      type: Number,
      default: 16,
    },
    ratio: {
      type: Number,
      default: 1,
    },
    isSummaryView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    isSection() {
      return this.element.related_model_type === 'MenuSection'
    }, // isSection
    relatedId() {
      return this.element.related_model_id
    }, // relatedId
    defaultFontSize() {
      return `${this.fontSize * this.ratio}px`
    },
    numItems() {
      return _get(this.element, 'content.data.items_json', []).length
    }, // numItems
    type() {
      return this.element.elType || ''
    }, // type
    icon() {
      switch (this.type) {
        case 'Section':
          return 'article'
        case 'Callout':
          return 'featured_video'
        case 'Phone':
          return 'local_phone'
        case 'Address':
          return 'pin_drop'
        case 'Hours':
          return 'access_time'

        default:
          return false
      }
    }, // icon
    text() {
      let text = ''
      if (this.element.content && this.element.content.text) {
        text = this.element.content.text
      } else if (this.element.title) {
        text = this.element.title
      }

      return text
    },
  },
}
</script>

<style lang="scss">
.force-ems {
  .pa-1,
  .py-1,
  .pb-1 {
    padding-bottom: 0.25em !important;
  }
  .pa-2,
  .py-2,
  .pb-2 {
    padding-bottom: 0.5em !important;
  }
  .pa-3,
  .py-3,
  .pb-3 {
    padding-bottom: 1em !important;
  }
  .pa-4,
  .py-4,
  .pb-4 {
    padding-bottom: 1.5em !important;
  }
  .pa-5,
  .py-5,
  .pb-5 {
    padding-bottom: 3em !important;
  }

  .pa-1,
  .py-1,
  .pt-1 {
    padding-top: 0.25em !important;
  }
  .pa-2,
  .py-2,
  .pt-2 {
    padding-top: 0.5em !important;
  }
  .pa-3,
  .py-3,
  .pt-3 {
    padding-top: 1em !important;
  }
  .pa-4,
  .py-4,
  .pt-4 {
    padding-top: 1.5em !important;
  }
  .pa-5,
  .py-5,
  .pt-5 {
    padding-top: 3em !important;
  }

  .pa-1,
  .px-1,
  .pl-1 {
    padding-left: 0.25em !important;
  }
  .pa-2,
  .px-2,
  .pl-2 {
    padding-left: 0.5em !important;
  }
  .pa-3,
  .px-3,
  .pl-3 {
    padding-left: 1em !important;
  }
  .pa-4,
  .px-4,
  .pl-4 {
    padding-left: 1.5em !important;
  }
  .pa-5,
  .px-5,
  .pl-5 {
    padding-left: 3em !important;
  }

  .pa-1,
  .px-1,
  .pr-1 {
    padding-right: 0.25em !important;
  }
  .pa-2,
  .px-2,
  .pr-2 {
    padding-right: 0.5em !important;
  }
  .pa-3,
  .px-3,
  .pr-3 {
    padding-right: 1em !important;
  }
  .pa-4,
  .px-4,
  .pr-4 {
    padding-right: 1.5em !important;
  }
  .pa-5,
  .px-5,
  .pr-5 {
    padding-right: 3em !important;
  }

  .ma-1,
  .my-1,
  .mb-1 {
    margin-bottom: 0.25em !important;
  }
  .ma-2,
  .my-2,
  .mb-2 {
    margin-bottom: 0.5em !important;
  }
  .ma-3,
  .my-3,
  .mb-3 {
    margin-bottom: 1em !important;
  }
  .ma-4,
  .my-4,
  .mb-4 {
    margin-bottom: 1.5em !important;
  }
  .ma-5,
  .my-5,
  .mb-5 {
    margin-bottom: 3em !important;
  }

  .ma-1,
  .my-1,
  .mt-1 {
    margin-top: 0.25em !important;
  }
  .ma-2,
  .my-2,
  .mt-2 {
    margin-top: 0.5em !important;
  }
  .ma-3,
  .my-3,
  .mt-3 {
    margin-top: 1em !important;
  }
  .ma-4,
  .my-4,
  .mt-4 {
    margin-top: 1.5em !important;
  }
  .ma-5,
  .my-5,
  .mt-5 {
    margin-top: 3em !important;
  }

  .ma-1,
  .mx-1,
  .ml-1 {
    margin-left: 0.25em !important;
  }
  .ma-2,
  .mx-2,
  .ml-2 {
    margin-left: 0.5em !important;
  }
  .ma-3,
  .mx-3,
  .ml-3 {
    margin-left: 1em !important;
  }
  .ma-4,
  .mx-4,
  .ml-4 {
    margin-left: 1.5em !important;
  }
  .ma-5,
  .mx-5,
  .ml-5 {
    margin-left: 3em !important;
  }

  .ma-1,
  .mx-1,
  .mr-1 {
    margin-right: 0.25em !important;
  }
  .ma-2,
  .mx-2,
  .mr-2 {
    margin-right: 0.5em !important;
  }
  .ma-3,
  .mx-3,
  .mr-3 {
    margin-right: 1em !important;
  }
  .ma-4,
  .mx-4,
  .mr-4 {
    margin-right: 1.5em !important;
  }
  .ma-5,
  .mx-5,
  .mr-5 {
    margin-right: 3em !important;
  }
}
</style>
