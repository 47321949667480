<template>
  <VContainer fluid class="offline-alert">
    <VLayout row wrap justify-center fill-height align-center>
      <VFlex xs10 sm6 md5>
        <VCard pa-5 flat color="transparent">
          <h2 class="mb-2"
            >Sorry but we're having trouble loading this website.</h2
          >
          <p
            >Please try <VBtn outline small :href="url">reloading</VBtn> or come
            back later.</p
          >
        </VCard>
      </VFlex>
    </VLayout>
  </VContainer>
</template>

<script>
const url = window.location.href
export default {
  name: 'OfflineAlert',
  components: {},
  data() {
    return {
      url,
    }
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,700&display=swap');
</style>
<style lang="scss">
.offline-alert * {
  font-family: 'Montserrat', sans-serif;
  h2 {
    font-style: italic;
    font-weight: 700;
  }
  p {
    font-weight: 500;
    color: #6b6b6b;
  }
}
</style>
