require('dotenv').config()

/// /////////////////////////
//    Resto API
/// /////////////////////////'

export const RESTO_API_BASE_URL = process.env.VUE_APP_RESTO_API_BASE_URL

/// /////////////////////////
//    SENTRY
/// /////////////////////////

export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN
export const SENTRY_PROJECT_ID = process.env.VUE_APP_SENTRY_PROJECT_ID

/// /////////////////////////
//    Instagram
/// /////////////////////////

export const IG_ACCESS_TOKEN = process.env.VUE_APP_IG_ACCESS_TOKEN

/// /////////////////////////
//    Google Maps
/// /////////////////////////

export const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY

/// /////////////////////////
//    Cloudinary / Assets
/// /////////////////////////

export const ASSETS_BASE_URL = process.env.VUE_APP_ASSETS_BASE_URL
