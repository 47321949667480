<template>
  <VCard v-on-clickaway="turnOffEditing" class="pa-2 elevation-0 transparent">
    <div v-if="!imageSrc" class="drag-handle">
      <BaseEditable
        content="Enter Image URL here..."
        @update="updatedTempSrc"
      />
      <BaseButtonOutline color="blue" small @click="savedSrc"
        >Save</BaseButtonOutline
      >
    </div>
    <template v-else>
      <Portal v-if="showAdjuster" to="layout-editor-header">
        <div class="layout-image-size-adjuster">
          <a
            href="#"
            class="layout-image-size-adjuster-close"
            @click.prevent="turnOffEditing"
            >&times;</a
          >
          <input v-model="size" type="range" min="20" max="400" />
        </div>
      </Portal>
      <div style="text-align: center;">
        <img
          class="drag-handle"
          style="display: inline-block"
          :src="imageSrc"
          :height="height"
          @click="toggleEditing"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'
import _get from 'lodash/get'

export default {
  name: 'LayoutElementTypeImage',
  mixins: [clickaway],
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {},
    },
    ratio: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isEditing: false,
      img: null,
      size: 100,
      debounceTimer: null,
      src: null,
      tempSrc: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('layoutEditor', ['isShowingLayoutEditUI']),
    computedSize() {
      return _get(this.element, 'content.height', this.size)
    },
    imageSrc: {
      get() {
        if (this.src) {
          return this.src
        }
        if (this.element.content && this.element.content.src) {
          return this.element.content.src
        }

        return ''
      },
      set(newSrc) {
        let element = { ...this.element }
        element.content.src = newSrc
        element.title = 'image'

        this.updateElement(element)
      },
    },
    height() {
      return this.computedSize * this.ratio
    },
    showAdjuster() {
      return this.isEditing && this.isShowingLayoutEditUI
    },
  },
  watch: {
    size() {
      if (this.debounceTimer) {
        return
      }
      this.debounceTimer = setTimeout(this.updateHeight, 1000)
    },
  },
  methods: {
    ...mapActions('layouts', ['updateElement']),
    turnOffEditing() {
      this.isEditing = false
    },
    updatedTempSrc(src) {
      this.tempSrc = src
    },
    savedSrc() {
      this.imageSrc = this.tempSrc
      this.src = this.tempSrc
    },
    toggleEditing() {
      if (!this.loggedIn || !this.isShowingLayoutEditUI) {
        return
      }
      this.isEditing = !this.isEditing
    },
    updateHeight() {
      let element = { ...this.element }
      element.content.height = this.size
      this.updateElement(element)
      this.debounceTimer = null
    },
  },
}
</script>

<style scoped>
.layout-image-size-adjuster {
  position: relative;
  padding: 20px;
  background-color: blue;
}
.layout-image-size-adjuster-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  color: white;
  text-decoration: none !important;
}
</style>
