<template>
  <div class="modals-and-snackbars">
    <AuthModal />
    <AuthSnackbar />
    <LoginSuccessSnackbar />
    <EditorSnackbar />
    <SendEmailModal
      :show="getEmailModalState"
      :header-class="emailHeaderClass"
      :color="emailHeaderColor"
      @hide="hideEmailModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import AuthModal from '@modals/AuthModal'
import AuthSnackbar from '@auth/AuthSnackbar'
import LoginSuccessSnackbar from '@auth/LoginSuccessSnackbar'
import EditorSnackbar from '@editor/EditorSnackbar'
import SendEmailModal from '@modals/SendEmailModal'

export default {
  name: 'ModalsAndSnackbars',
  components: {
    AuthModal,
    AuthSnackbar,
    LoginSuccessSnackbar,
    EditorSnackbar,
    SendEmailModal,
  },
  props: {
    emailHeaderClass: {
      type: String,
      default: 'bubs-charcoal white--text',
    },
    emailHeaderColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('nav', ['getEmailModalState']),
  },
  methods: {
    ...mapActions('nav', ['hideEmailModal']),
  },
}
</script>
