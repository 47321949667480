import Vue from 'vue'
import './plugins/vuetify'
import './plugins/google-maps.js'
import App from './app'
import router from '@router'
import store from '@store/store'
import '@components/Base'
import '@components/Blocks'
import '@components/DataProviders'

import VueMq from 'vue-mq'
import PortalVue from 'portal-vue'

import VueShortkey from 'vue-shortkey'
var VueScrollTo = require('vue-scrollto')
Vue.use(VueShortkey)

Vue.use(VueScrollTo)

Vue.use(PortalVue)

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    xs: 414,
    sm: 600,
    md: 960,
    lg: 1264,
    xl: Infinity,
  },
  defaultBreakpoint: 'sm', // customize this for SSR
})
// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.config.devtools = process.env.VUE_APP_NODE_ENV !== 'production'

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
// if (
//   process.env.VUE_APP_TEST === 'e2e' ||
//   process.env.NODE_ENV === 'development'
// ) {
// Attach the app to the window, which can be useful
// for manually setting state in Cypress commands
// such as `cy.logIn()`.
window.vm = app
// }
