<template>
  <div
    class="loader--wrap"
    :style="[{ backgroundColor: bgColor ? bgColor : 'inherit' }]"
  >
    <SquareLoader :color="color"></SquareLoader>
    <slot />
  </div>
</template>

<script>
import SquareLoader from 'vue-spinner/src/SquareLoader'

export default {
  name: 'BaseLoader',
  components: { SquareLoader },
  props: {
    bgColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#6cbd7d',
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';

.loader--wrap {
  padding-top: 20vh;
  padding-bottom: 20vh;
  text-align: center;
  background-color: white;
}
</style>
