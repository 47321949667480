import Vue from 'vue'
import api from '@services/api'
import normalizeFns, { normalizeMenu, denormalizeMenus } from '@utils/normalizr'

import {
  ENTITY_LOOKUP,
  ROUTE_LOOKUP,
  ENTITY_PARENT_NAMES,
  ENTITY_NAMES,
} from '@constants/lookupTables'

import _isEmpty from 'lodash/isEmpty'
import _forEach from 'lodash/forEach'
import _invert from 'lodash/invert'

import {
  CACHE_MENU,
  SET_MENUS,
  SET_ENTITIES,
  MERGE_ENTITIES,
  CREATE_ENTITY,
  UPDATE_ENTITY,
  REMOVE_ENTITY,
  UPDATE_CHILD_ID,
} from '@constants/mutations'

export const state = {
  cached: {},
  menus: [],
  entities: {},
}

export const mutations = {
  [CACHE_MENU](state, menu) {
    Vue.set(state.cached, menu.id, menu)
  }, // CACHE_MENU

  [SET_MENUS](state, menus) {
    state.menus = menus
  }, // SET_MENUS

  [SET_ENTITIES](state, entities) {
    state.entities = entities
  },
  [MERGE_ENTITIES](state, updatedEntities) {
    state.entities = { ...state.entities, ...updatedEntities }
  },
  [UPDATE_ENTITY](state, { id, key, val }) {
    Vue.set(state.entities[id], key, val)
  },
} // mutations

export const getters = {
  getObjRouteFromEntity: (state) => (entityName) => {
    let invertedEntityLookup = _invert(ENTITY_LOOKUP)
    return ROUTE_LOOKUP[invertedEntityLookup[entityName]]
  },

  getNormalizeFunctionFromEntity: (state) => (entityName) => {
    let invertedEntityLookup = _invert(ENTITY_LOOKUP)
    return normalizeFns[`normalize${invertedEntityLookup[entityName]}`]
  },

  getMenus: (state) => (ids) =>
    !ids ? [] : ids.map((id) => state.entities[id]),

  getMenu: (state) => (id) => state.entities[id] || null,

  getDirtyMenus: (state) =>
    Object.keys(state.entities).filter((id) => !!state.entities[id].isDirty),

  //
  // Modification Getters
  //

  isEmptySectionItemMod: () => (entityName, modVal, modKey) => {
    if (entityName !== ENTITY_NAMES.SECTIONS) {
      return false
    }
    if (['itemsToSave', 'itemMods'].includes(modKey) && _isEmpty(modVal)) {
      return true
    }
  }, // isEmptySectionItemMod

  getMenusToPublish: (state, getters) => {
    let dirtyMenus = getters.getDirtyMenus
    return dirtyMenus.length ? denormalizeMenus(getters.getDirtyMenus) : false
  }, // getMenusToPublish
} // getters

export const actions = {
  async fetchMenu({ getters, dispatch }, { menuId }) {
    //   // 1. Check if we already have the user as a current user.
    //   const { currentUser } = rootState.auth
    //   if (currentUser && currentUser.username === username) {
    //     return Promise.resolve(currentUser)
    //   }
    // 2. Check if we've already fetched and cached the loc.
    const matchedMenu = getters.getMenu(menuId)
    if (matchedMenu) {
      return matchedMenu
    }
    // 3. Fetch the loc from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(`/menus/${menuId}`)
      let menu = await dispatch('processMenuResponse', response)
      return menu
    } catch (err) {
      return Promise.reject(err)
    }
  }, // fetchMenu

  async tryMenuMod({ dispatch }, { entityName, entityId, mods }) {
    dispatch(
      'entities/tryEntityMod',
      { entityName, entityId, mods },
      { root: true }
    )
  }, // tryMenuMod

  async publishMenuChanges({ dispatch }) {
    let isValid = await dispatch('auth/validate', null, { root: true })
    if (isValid) {
      dispatch('publishMenus')
    }
  }, // publishMenuChanges

  async publishMenus({ getters, dispatch }) {
    let dirtyMenus = getters.getMenusToPublish
    _forEach(dirtyMenus, async (menu, menuId) => {
      try {
        let updatedMenuRes = await api.updateMenu(menu)
        let updatedMenu = await dispatch('processMenuResponse', updatedMenuRes)
        return updatedMenu
      } catch (error) {
        console.warn('couldnt save the menu')
        throw new Error(error)
      }
    })
  }, // publishMenus

  async processMenuResponse({ dispatch, commit }, res) {
    let { entities, result } = await normalizeMenu(res.data)
    const menu = entities.menus[result]

    commit(CACHE_MENU, menu)
    await dispatch('entities/setAllEntities', entities, { root: true })
    return menu
  }, // processMenuResponse

  async publishRemovedEntities({ getters, dispatch }) {
    let removedGuys = getters.getRemovedEntities

    _forEach(removedGuys, async (collection, entityName) => {
      _forEach(collection, async (entity, entityId) => {
        try {
          let res = await api.deleteEntity(entityName, entity.id)
          // remove entity from collection and from parent
          dispatch('removeEntityFromCollection', {
            parentEntityName: ENTITY_PARENT_NAMES[entityName],
            parentId: entity.removedFromParent,
            entityName,
            entityId,
          })
          dispatch('removeRemovedEntity', {
            entityName,
            entityId,
          })
          return res
        } catch (err) {
          Promise.reject(err)
        }
      })
    })
  }, // publishRemovedEntities

  udpateParentItem(
    { commit, dispatch },
    { entityName, stagingId, newId, parentEntityName }
  ) {
    commit(UPDATE_CHILD_ID, {
      entityName,
      stagingId,
      newId,
      parentEntityName,
    })
  }, // udpateParentItem

  replaceTempEntityWithResponse(
    { commit, getters },
    { res, entityName, stagingId, newId }
  ) {
    let normalizeFn = getters.getNormalizeFunctionFromEntity(entityName)
    let normalizedContent = normalizeFn(res.data)
    let newEntity = normalizedContent.entities[entityName][newId]
    // Remove staged
    commit(REMOVE_ENTITY, { entityName, entityId: stagingId })
    // Add real entity
    commit(CREATE_ENTITY, { entityName, newEntity })
  }, // replaceTempEntityWithResponse

  updateSectionFromResponse({ commit, getters, dispatch }, { res, sectionId }) {
    let sectionEntityName = ENTITY_NAMES.SECTIONS
    let itemEntityName = ENTITY_NAMES.ITEMS
    let sectionNormalizeFn = getters.getNormalizeFunctionFromEntity(
      sectionEntityName
    )

    // Dupe the section response w/o the 'new_items' key
    let sectionRes = { ...res.data }
    delete sectionRes.new_items

    let normalizedResponse = sectionNormalizeFn(sectionRes)
    let newSectionContent =
      normalizedResponse.entities[sectionEntityName][sectionId]

    // Loop over any new items that come back from a nested update call
    // This will give them a real db_id as well as the freshest data
    if (res.data.new_items) {
      res.data.new_items.map((pair) => {
        dispatch('replaceTempEntityWithResponse', {
          res,
          entityName: itemEntityName,
          stagingId: pair.vue_id,
          newId: pair.db_id,
        })
      })
    }

    // Use the new normalized data to udpate all the section.items
    newSectionContent.items.map((itemId) => {
      let newItemContent = normalizedResponse.entities[itemEntityName][itemId]
      dispatch(
        'entities/updateEntityFromResponse',
        {
          entityName: itemEntityName,
          entityId: itemId,
          newContent: newItemContent,
        },
        { root: true }
      )
    })
    // Update the section with its new data
    dispatch(
      'entities/updateEntityFromResponse',
      {
        entityName: sectionEntityName,
        entityId: sectionId,
        newContent: newSectionContent,
      },
      { root: true }
    )
  }, // updateSectionFromResponse

  async liveEditMenuMod(
    { dispatch, getters, rootGetters },
    { entityName, entityId, mods }
  ) {
    if (rootGetters['auth/isAutosaveEnabled'] && rootGetters['auth/loggedIn']) {
      let objRoute = getters.getObjRouteFromEntity(entityName)
      let res = await dispatch('patchEntity', {
        objRoute,
        entityId,
        mods,
      })
      dispatch('updateEntityFromResponse', {
        res,
        entityName,
        entityId,
      })
    }
  }, // liveEditMenuMod
} // actions

export const namespaced = true
