var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VContainer',{staticClass:"menu",attrs:{"fluid":"","pa-0":""}},[_vm._l((_vm.menuIds),function(menuId){return [_c('WithMenu',{key:("menu-" + menuId),attrs:{"id":menuId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isMenuLoading = ref.isMenuLoading;
var menu = ref.menu;
return [_c('BaseMenuComponent',{attrs:{"name":"MenuLayout","menu":menu},on:{"dirtyUpdate":_vm.adjustEditorDirtScore}},[(!isMenuLoading)?_c('VLayout',{attrs:{"row":"","wrap":""}},[_vm._l((menu.sections),function(sectionId){return [_vm._t("default",[_c('VFlex',{key:("section-" + sectionId),attrs:{"xs12":"","grow":""}},[_c('WithMenuSection',{attrs:{"id":sectionId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isSectionLoading = ref.isSectionLoading;
var section = ref.section;
var itemMods = ref.itemMods;
var myMods = ref.myMods;
return [(section)?_c('BaseMenuComponent',{attrs:{"name":"MenuSection","entity":section,"item-mods":itemMods,"my-mods":myMods}}):_vm._e()]}}],null,true)})],1)],{"sectionId":sectionId})]})],2):_vm._e()],1)]}}],null,true)})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }