var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor"},[(_vm.allowLinks)?_c('EditorMenuBubble',{staticClass:"menububble",attrs:{"editor":_vm.editor},on:{"hide":_vm.hideLinkMenu},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var getMarkAttrs = ref.getMarkAttrs;
var menu = ref.menu;
return [_c('div',{staticClass:"menububble",class:{ 'is-active': menu.isActive },style:(("left: " + (menu.left) + "px; bottom: " + (menu.bottom) + "px;"))},[(_vm.linkMenuIsActive)?_c('form',{staticClass:"menububble__form",on:{"submit":function($event){$event.preventDefault();return _vm.setLinkUrl(commands.link, _vm.linkUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.linkUrl),expression:"linkUrl"}],ref:"linkInput",staticClass:"menububble__input",attrs:{"type":"text","placeholder":"https://"},domProps:{"value":(_vm.linkUrl)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hideLinkMenu($event)},"input":function($event){if($event.target.composing){ return; }_vm.linkUrl=$event.target.value}}}),_c('button',{staticClass:"menububble__button",attrs:{"type":"button"},on:{"click":function($event){return _vm.setLinkUrl(commands.link, null)}}},[_c('VIcon',{attrs:{"color":"white","small":"","right":""}},[_vm._v("remove")])],1)]):[_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.link() },on:{"click":function($event){_vm.showLinkMenu(getMarkAttrs('link'))}}},[_c('span',[_vm._v(_vm._s(isActive.link() ? 'Update Link' : 'Add Link'))]),_c('VIcon',{attrs:{"color":"white","small":"","right":""}},[_vm._v("link")])],1)]],2)]}}],null,false,2783369479)}):_vm._e(),_c('EditorContent',{directives:[{name:"toggle-select",rawName:"v-toggle-select"},{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.deselect),expression:"deselect"}],class:[
      'resto-editable',
      { 'r-edit': _vm.loggedIn },
      { 'is-dirty': _vm.isDirty },
      { hovering: _vm.showHover },
      { 'empty-text': _vm.isEmpty },
      { 'was-deleted': _vm.wasDeleted },
      { ph: _vm.placeholder },
      _vm.isMutliLine ? 'pm-ML' : 'pm-SL' ],attrs:{"editor":_vm.editor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }