const selectElementText = function(el) {
  let doc = document
  let range
  let selection
  if (doc.body.createTextRange) {
    range = document.body.createTextRange()
    range.moveToElementText(el)
    range.select()
  } else if (window.getSelection) {
    selection = window.getSelection()
    range = document.createRange()
    range.selectNodeContents(el)
    selection.removeAllRanges()
    selection.addRange(range)
  }
}
export default selectElementText
