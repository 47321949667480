<template>
  <VLayout
    class="page-section-split-half"
    pa-0
    v-bind="$attrs"
    my-0
    fill-height
    row
    wrap
  >
    <VFlex xs12 sm6
      ><slot name="left">
        <h2>
          First Half
        </h2>
      </slot></VFlex
    >
    <VFlex xs12 sm6
      ><slot name="right">
        <h2>
          Second Half
        </h2>
      </slot></VFlex
    >
  </VLayout>
</template>

<script>
export default {
  name: 'BlockSplitHalf',
  components: {},
  data() {
    return {}
  },
}
</script>
