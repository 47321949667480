var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Draggable',{class:[
    'layout-elements',
    _vm.isShowingLayoutEditUI
      ? 'layout-elements--active'
      : 'layout-elements--hiding-ui',
    {
      'layout-elements--empty': _vm.empty,
      'drag-target': _vm.isTargetDropZone,
    } ],attrs:{"group":"elements","disabled":!_vm.isShowingEditUI,"handle":".drag-handle","move":_vm.moveFn},on:{"end":_vm.endFn},model:{value:(_vm.layoutElements),callback:function ($$v) {_vm.layoutElements=$$v},expression:"layoutElements"}},[(!_vm.layoutElements.length)?_c('div',{staticClass:"text-xs-center placeholder"},[_vm._v("Drag Items Here")]):_vm._l((_vm.layoutElements),function(element,e){return [_c('div',{key:element.id || e,staticClass:"layout-element--draggable"},[(element.id)?_c('WithLayoutElement',{key:element.id,attrs:{"element-id":element.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var isElementLoading = ref.isElementLoading;
  var theElement = ref.theElement;
return [(!isElementLoading && theElement)?[_c('LayoutElement',{attrs:{"element":theElement,"ratio":_vm.ratio,"font-size":_vm.fontSize},on:{"hook:mounted":function($event){_vm.loaded++}}})]:_vm._e()]}}],null,true)}):_c('LayoutElement',{attrs:{"element":element,"ratio":_vm.ratio,"font-size":_vm.fontSize},on:{"hook:mounted":function($event){_vm.loaded++}}})],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }