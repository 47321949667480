import store from '@store/store'
import { ENTITY_NAMES } from '../constants/lookupTables'

import { normalize, denormalize, schema } from 'normalizr'

const siteSchema = new schema.Entity('sites')

const itemAttrSchema = new schema.Entity('attributes')
export const normalizeMenuItemAttr = function(data) {
  return normalize(data, itemAttrSchema)
}

const menuItemSchema = new schema.Entity('items', {
  attributes: [itemAttrSchema],
})

export const normalizeMenuItem = function(data) {
  return normalize(data, menuItemSchema)
}

const menuSectionSchema = new schema.Entity('sections')
menuSectionSchema.define({
  items_json: [menuItemSchema],
  site: siteSchema,
  sub_menu_sections: [menuSectionSchema],
})

export const normalizeMenuSection = function(data) {
  return normalize(data, menuSectionSchema)
}

const menuSchema = new schema.Entity('menus', {
  sections: [menuSectionSchema],
  site: siteSchema,
})

const menuListSchema = new schema.Array(menuSchema)

export const normalizeMenus = function(data) {
  return normalize(data, menuListSchema)
}
export const normalizeMenu = function(data) {
  return normalize(data, menuSchema)
}

export const normalizeLocation = function(data) {
  return normalize(data, locationSchema)
}

const layoutElementSchema = new schema.Entity('elements')

export const normalizeLayoutElement = function(data) {
  return normalize(data, layoutElementSchema)
}

const layoutRegionSchema = new schema.Entity('regions', {
  elements: [layoutElementSchema],
})

export const normalizeLayoutRegion = function(data) {
  return normalize(data, layoutRegionSchema)
}

const layoutSchema = new schema.Entity('layouts', {
  regions: [layoutRegionSchema],
})

export const normalizeLayout = function(data) {
  return normalize(data, layoutSchema)
}

const locationSchema = new schema.Entity('locations', {
  menus: [menuSchema],
  layouts: [layoutSchema],
})

export const denormalizeItems = function(itemIds, entities = false) {
  let allItemEntities = {
    items:
      entities || store.getters['entities/getAllOfEntity'](ENTITY_NAMES.ITEMS),
  }
  return denormalize({ items: itemIds }, locationSchema, allItemEntities).items
}

export const denormalizeSections = function(sectionIds) {
  let entities = {}
  Object.keys(store.state).map((entityName) => {
    entities[entityName] = Object.freeze(store.state[entityName].entities)
  })
  let denormalizedSections = denormalize(
    { sections: sectionIds },
    menuSchema,
    entities
  )
  return denormalizedSections.sections
}

export const denormalizeMenus = function(menuIds) {
  let entities = {}
  Object.keys(store.state).map((entityName) => {
    entities[entityName] = Object.freeze(store.state[entityName].entities)
  })
  let denormalizedMenus = denormalize(
    { menus: menuIds },
    locationSchema,
    entities
  )
  return denormalizedMenus.menus
}

export default {
  normalizeMenuItemAttr,
  // normalizeMenuItemAddon,
  normalizeMenuItem,
  normalizeMenuSection,
  normalizeMenu,
  normalizeLocation,
  normalizeLayoutElement,
  normalizeLayoutRegion,
  normalizeLayout,
  denormalizeItems,
  denormalizeMenus,
}
