import {
  CACHE_LAYOUT,
  CACHE_LAYOUT_FIELD,
  CACHE_REGION,
  CACHE_ELEMENT,
  SET_ENTITIES,
  MERGE_ENTITIES,
  UPDATE_ENTITY,
} from '@constants/mutations'
import { SITE_SLUG } from '@constants/siteDetails'
import Store from '@store/store'

import api from '@services/api'
import Vue from 'vue'

export const state = {
  cachedLayouts: {},
  cachedRegions: {},
  cachedElements: {},
  cache: {
    usage: {},
    queries: {},
  },
  entities: {},
  byId: {},
}

export const getters = {
  getLayoutBySlug: (state) => (layoutSlug) => {
    return (
      Object.values(state.cachedLayouts).find((p) => p.slug === layoutSlug) ||
      false
    )
  },
  getLayout: (state) => (layoutId) => state.cachedLayouts[layoutId] || false,
  getLayouts: (state) => (ids) =>
    !ids ? [] : ids.map((id) => state.cachedLayouts[id]),
  getRegion: (state) => (regionId) => state.cachedRegions[regionId],
  getElement: (state) => (elementId) => state.cachedElements[elementId],
}

export const mutations = {
  [SET_ENTITIES](state, entities) {
    state.entities = entities
  },
  [MERGE_ENTITIES](state, updatedEntities) {
    state.entities = { ...state.entities, ...updatedEntities }
  },
  [CACHE_LAYOUT](state, newLayout) {
    Vue.set(state.cachedLayouts, newLayout.id, newLayout)
    Vue.set(state.entities, newLayout.id, newLayout)
  },
  [CACHE_LAYOUT_FIELD](state, { id, field, value }) {
    Vue.set(state.cachedLayouts[id], field, value)
  },
  [UPDATE_ENTITY](state, { id, key, val }) {
    Vue.set(state.entities[id], key, val)
  },
  [CACHE_REGION](state, newRegion) {
    newRegion.elements.forEach((element) => {
      Vue.set(state.cachedElements, element.id, element)
      if (
        element.type === 'related' &&
        element.related_model_type === 'MenuSection'
      ) {
        Store.dispatch(
          'sections/processSectionResponse',
          { data: element.related },
          { root: true }
        )
      }
    })
    Vue.set(state.cachedRegions, newRegion.id, newRegion)
  },
  [CACHE_ELEMENT](state, newElement) {
    Vue.set(state.cachedElements, newElement.id, newElement)
  },
}

export const actions = {
  async fetchLayoutBySlug({ commit, getters, rootState }, { layoutSlug }) {
    // 1. Check if we've already fetched and cached the layout.
    const matchedLayout = getters.getLayoutBySlug(layoutSlug)
    if (matchedLayout) {
      return matchedLayout
    }
    // 2. Fetch the layout from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(
        `/path-to-model/${SITE_SLUG}/layouts/${layoutSlug}`
      )

      const layout = response.data

      commit(CACHE_LAYOUT, layout)
      layout.regions.forEach((region) => {
        commit(CACHE_REGION, region)
      })
      return layout
    } catch (err) {
      return err
    }
  }, // fetchLayoutBySlug

  async fetchLayout({ commit, getters, rootState }, { layoutId }) {
    // 1. Check if we've already fetched and cached the layout.
    const matchedLayout = getters.getLayout(layoutId)
    if (matchedLayout) {
      return matchedLayout
    }
    // 2. Fetch the layout from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(`layouts/${layoutId}`)

      const layout = response.data

      commit(CACHE_LAYOUT, layout)
      layout.regions.forEach((region) => {
        commit(CACHE_REGION, region)
      })
      return layout
    } catch (err) {
      return err
    }
  }, // fetchLayout

  async fetchRegion({ commit, getters, rootState }, { regionId }) {
    // 1. Check if we've already fetched and cached the layout.
    const matchedRegion = getters.getRegion(regionId)
    if (matchedRegion) {
      return matchedRegion
    }
    // 2. Fetch the layout from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(`/layout-regions/${regionId}`)

      const region = response.data

      commit(CACHE_REGION, region)
      return region
    } catch (err) {
      return err
    }
  },

  async fetchElement({ commit, getters, rootState }, { elementId }) {
    // 1. Check if we've already fetched and cached the layout.
    const matchedElement = getters.getElement(elementId)
    if (matchedElement) {
      return matchedElement
    }
    // 2. Fetch the layout from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(`/layout-elements/${elementId}`)

      const element = response.data

      commit(CACHE_ELEMENT, element)
      return element
    } catch (err) {
      return err
    }
  },

  async updateLayout({ commit }, data) {
    try {
      const response = await api.patch(`/layouts/${data.id}`, data)

      const updatedLayout = response.data

      commit(CACHE_LAYOUT, updatedLayout)

      return updatedLayout
    } catch (err) {
      return err
    }
  },

  async updateRegion({ commit, getters, rootState }, { regionId, data }) {
    try {
      const response = await api.patch(`/layout-regions/${regionId}`, data)

      const updatedRegion = response.data

      commit(CACHE_REGION, updatedRegion)
      return updatedRegion
    } catch (err) {
      return err
    }
  },

  async updateElement({ commit, rootState }, elementData) {
    try {
      const response = !elementData.id
        ? await api.post(
            `sites/${rootState.site.id}/layout-elements`,
            elementData
          )
        : await api.patch(`/layout-elements/${elementData.id}`, elementData)

      const updatedElement = response.data

      commit(CACHE_ELEMENT, updatedElement)

      return updatedElement
    } catch (err) {
      return err
    }
  },

  async checkForUpdates({ commit }, layout) {
    try {
      const { data: updatedLayout } = await api.get(`/layouts/${layout.id}`)
      if (updatedLayout) {
        commit(CACHE_LAYOUT, updatedLayout)
        updatedLayout.regions.forEach((region) => {
          commit(CACHE_REGION, region)
        })
      }
    } catch (error) {
      Promise.reject(error)
    }
  }, // checkForUpdates
} // actions
