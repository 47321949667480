<script>
import { mapActions, mapGetters } from 'vuex'
import arrayMove from 'array-move'

export default {
  props: {
    id: {
      type: [String, Number],
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isSectionLoading: false,
      isHovering: false,
      isSelected: false,
      placeholderSection: false,
    }
  },
  computed: {
    ...mapGetters('entities', ['getNonRemovedEntitySet']),
    ...mapGetters('items', ['getItems']),
    ...mapGetters('sections', [
      'getSection',
      'getSectionMods',
      'getSectionItemMods',
    ]),
    section() {
      return this.getSection(this.id) || this.placeholderSection
    }, // section

    myMods() {
      return this.getSectionMods(this.id)
    }, // myMods

    itemMods() {
      return this.getSectionItemMods(this.id)
    }, // itemMods
  },
  watch: {
    id: {
      async handler(id) {
        this.isSectionLoading = true
        await this.getSection(id)
        this.isSectionLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('sections', ['updateSectionItems']),
    async dragEventAction(evt) {
      let newItemSet = this.sortOrder ? [...this.sortOrder] : []
      let sectionId = this.entity.id
      if (evt.moved) {
        let currOrder = this.sortOrder.length
          ? this.sortOrder
          : this.sortedItems.map((i) => i.id)
        let newOrder = arrayMove(
          currOrder,
          evt.moved.oldIndex,
          evt.moved.newIndex
        )

        await this.updateSectionItems({ items: newOrder, sectionId })
      }
      if (evt.added) {
        let itemId = evt.added.element.id
        newItemSet.splice(evt.added.newIndex, 0, itemId)
        this.updateSectionItems({ items: newItemSet, sectionId })
      }
      if (evt.removed) {
        newItemSet.splice(evt.removed.oldIndex, 1)
        this.updateSectionItems({ items: newItemSet, sectionId })
      }
    }, // dragEventAction
  },
  render() {
    let {
      section,
      isHovering,
      isSectionLoading,
      myMods,
      itemMods,
      itemsToSave,
      sortedItems,
      subsections,
      updateSection,
      updateItemAction,
      addItemAction,
      dragEventAction,
    } = this
    return this.$scopedSlots.default({
      section,
      isHovering,
      isSectionLoading,
      myMods,
      itemMods,
      itemsToSave,
      sortedItems,
      subsections,
      updateSection,
      updateItemAction,
      addItemAction,
      dragEventAction,
    })
  },
}
</script>
