<script>
// import TestLayouts from '@store/TestLayouts'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithLayout',
  props: {
    slug: {
      type: [String, Boolean],
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLayoutLoading: false,
    }
  },
  computed: {
    ...mapGetters('layouts', ['getLayoutBySlug', 'getLayout']),
    theLayout() {
      if (this.isLayoutLoading) return false
      if (this.slug) {
        return this.getLayoutBySlug(this.slug)
      } else if (this.id) {
        return this.getLayout(this.id)
      } else {
        return false
      }
    }, // theLayout
    regions() {
      return this.theLayout.regions || []
    }, // regions
  },
  watch: {
    slug: {
      async handler(val) {
        this.isLayoutLoading = true
        if (this.slug) {
          await this.fetchLayoutBySlug({
            layoutSlug: this.slug || this.$route.params.layoutSlug,
          })
        }
        if (this.id) {
          await this.fetchLayout({
            layoutId: this.id,
          })
        }
        this.isLayoutLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('layouts', ['fetchLayoutBySlug', 'fetchLayout']),
  },
  render() {
    return this.$scopedSlots.default({
      theLayout: this.theLayout,
      isLayoutLoading: this.isLayoutLoading,
      regions: this.regions,
    })
  },
}
</script>
