<script>
// import Tests from '@store/Tests'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithLayoutElement',
  props: {
    elementId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isElementLoading: false,
    }
  },
  computed: {
    ...mapGetters('layouts', ['getElement']),
    theElement() {
      return this.isElementLoading ? {} : (this.getElement(this.elementId) || {})
    }, // theElement
  },
  watch: {
    elementId: {
      async handler(val) {
        if (!this.elementId) {
          return
        }
        this.isElementLoading = true
        await this.fetchElement({
          elementId: this.elementId,
        })
        this.isElementLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('layouts', ['fetchElement']),
  },
  render() {
    return this.$scopedSlots.default({
      theElement: this.theElement,
      isElementLoading: this.isElementLoading,
    })
  },
}
</script>
