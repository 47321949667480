import { mapGetters } from 'vuex'

var directive = {
  // Bind a click action to activate/select the item
  bind: (el, binding, vnode) => {
    el.addEventListener(
      'click',
      (e) => {
        log('click')

        // if (!vnode.context.isShowingEditUI) {
        //   return false
        // }

        if (vnode.context.selected) {
          log('already selected')
        }
        vnode.context.setAsSelected()
        vnode.context.setSelectionTimeout()
      },
      false
    )
  },

  componentUpdated: (el, binding, vnode) => {
    // log('component updated');
    let isSelected = vnode.context.selected

    let onClass = 'selected'
    let offClass = 'not-selected'

    el.classList.toggle(offClass, !isSelected)
    el.classList.toggle(onClass, !!isSelected)
  },
}

var debug = false

function log(msg) {
  if (typeof debug !== 'undefined' && debug) {
    // console.log('[ ToggleSelect ] ' + msg)
  }
}

export var mixin = {
  directives: { 'toggle-select': directive },
  created() {
    log('created')
  },
  data() {
    return {
      selected: false,
      showUI: false,
      disabled: false,
      selectionTimeout: null,
    }
  },
  computed: {
    isEditable() {
      return !this.disabled && !!this.loggedIn
    },
    ...mapGetters('editor', ['isShowingEditUI']),
    ...mapGetters('auth', ['loggedIn']),
  },
  methods: {
    deselect() {
      log('deselect')
      this.selected = false
      this.showDescription = false
    },
    setSelectionTimeout() {
      clearTimeout(this.selectionTimeout)
      this.selectionTimeout = setTimeout(() => {
        this.showUI = false
      }, 1200)
    }, // setSelectionTimeout

    setAsSelected() {
      this.selected = true
      this.showUI = true
    }, // setAsSelected

    disableEdit() {
      this.disabled = true
    },
    enableEdit() {
      this.disabled = false
    },
  },
  watch: {
    selected(val) {
      this.$emit('toggleSelect', val)
    },
  },
}

export default mixin
