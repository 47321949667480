import { SET_PATH_EDITABILITY } from '@constants/mutations'

export const state = {
  isEditable: false,
}
export const mutations = {
  [SET_PATH_EDITABILITY](state, isEditable) {
    state.isEditable = isEditable
  }, // SET_PATH_EDITABILITY
}
export const actions = {
  setPathEditability({ commit }, isEditable) {
    commit(SET_PATH_EDITABILITY, isEditable)
  }, // setPathEditability
}

export const getters = {
  isPathEditable: (state) => state.isEditable,
}
