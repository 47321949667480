<template>
  <VContainer
    class="block-default"
    :style="sectionStyles"
    v-bind="$attrs"
    :mx-0="$attrs.fullWidth"
    :px-0="$attrs.fullWidth || $attrs.fullBleedBg"
    :pa-0="$attrs.fullBleedContent"
  >
    <VLayout row align-center>
      <VFlex
        :class="[
          'text-xs-center section-content',
          { 'not-sm': !['xs', 'sm'].includes($mq) },
        ]"
      >
        <VContainer
          class="block-inner-container"
          :style="contentStyles"
          :fluid="$attrs.fluid"
          :px-0="$attrs.fullWidthContent"
          :pa-0="$attrs.fullBleedContent"
        >
          <slot />
        </VContainer>
      </VFlex>
      <div
        v-if="hasOverlay"
        class="overlay"
        :style="`background-color:${overlayColor};`"
      ></div>
    </VLayout>
  </VContainer>
</template>

<script>
export default {
  name: 'BlockDefault',
  props: {
    title: {
      type: String,
      default: null,
    },
    sectionStyles: {
      type: Object,
      default: () => {},
    },
    contentStyles: {
      type: Object,
      default: () => {},
    },
    hasOverlay: {
      type: Boolean,
      default: false,
    },
    overlayColor: {
      type: String,
      default: '#000000',
    },
  },
  data() {
    return {}
  },
}
</script>
