<template>
  <div class="contact-form">
    <transition name="fade-fast" mode="out-in">
      <div v-if="showSuccessMsg && loggedIn">
        <h2 class="asap--font grey--text">Welcome!</h2>
        <p class="r-title"
          >Hey, {{ user.first_name }}. You're logged in. Get to it!</p
        >
      </div>
      <!-- <div v-else-if="loggedIn">
        <h1 class="asap--font grey--text">Oh, hey there.</h1>
        <p class="r-title">You're already logged in.</p>
        <BaseButton class="ml-0" color="green lighten-2" small @click="logOut"
          >Log Out</BaseButton
        >
      </div> -->
      <div v-else-if="showErrorMsg">
        <h1 class="asap--font grey--text">Well *that* didn't work.</h1>
        <p class="r-title">There was a problem logging in.</p>
        <BaseButton
          class="ml-0"
          color="red lighten-2"
          small
          @click="showErrorMsg = false"
          >Try Again</BaseButton
        >
      </div>
      <VForm
        v-else
        ref="form"
        v-model="valid"
        class="auth-form"
        lazy-validation
        @submit.prevent="loginAction"
      >
        <VTextField
          v-model="email"
          :rules="[rules.required, rules.email]"
          :color="color"
          :disabled="loggedIn"
          type="email"
          label="email"
        />
        <VTextField
          v-model="password"
          :disabled="loggedIn"
          :rules="[rules.required]"
          :color="color"
          type="password"
          label="password"
        />
        <BaseButtonOutline
          v-if="!loggedIn"
          :disabled="!valid"
          :outline-color="color"
          class="ml-0"
          type="submit"
          :block="!showDismiss"
          :loading="isWorking"
          >Login</BaseButtonOutline
        >
        <BaseButtonOutline
          v-else
          outline-color="#222"
          class="ml-0"
          :block="!showDismiss"
          :loading="isWorking"
          @click="logOut"
          >Logout</BaseButtonOutline
        >
        <BaseButtonOutline
          v-if="showDismiss"
          outline-color="grey"
          class="ml-0"
          :disabled="isWorking"
          @click="dismiss"
          >Cancel</BaseButtonOutline
        >
      </VForm>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuthForm',
  props: {
    color: {
      type: String,
      default: '#6CBD7D',
    },
    showDismiss: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      email: '',
      password: '',
      showSuccessMsg: false,
      showErrorMsg: false,
      isWorking: false,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'loggedIn']),
    user() {
      return this.getUser
    },
  },
  methods: {
    ...mapActions('auth', ['logIn', 'logOut']),
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async loginAction(e) {
      if (this.$refs.form.validate()) {
        this.isWorking = true
        try {
          let { email, password } = this
          await this.logIn({ email, password })
          this.success()
        } catch (error) {
          this.error()
        } finally {
          this.isWorking = false
        }
      } else {
        return false
      }
    }, // loginAction

    success() {
      this.showSuccessMsg = true
      this.showErrorMsg = false
      this.reset()
      this.resetValidation()
      this.$emit('success')
      setTimeout(() => {
        this.$emit('dismiss')
      }, 1500)
    }, // success
    error() {
      this.showSuccessMsg = false
      this.showErrorMsg = true
    }, // error
    dismiss() {
      this.showSuccessMsg = false
      this.showErrorMsg = false
      this.$emit('dismiss')
    }, // dismiss
  },
}
</script>
