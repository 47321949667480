<template>
  <VFlex class="resto-byline" mt-4 text-xs-center white--text
    >Website &amp; Menus Powered by
    <a href="https://getresto.com" target="_blank" :style="linkStyles"
      >Resto</a
    ></VFlex
  >
</template>

<script>
export default {
  name: 'RestoByline',
  props: {
    linkColor: {
      type: null,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    linkStyles() {
      return {
        ...(this.linkColor && { color: this.linkColor }),
      }
    }, // linkStyles
  },
}
</script>

<style lang="scss">
.resto-byline {
  a {
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s ease;
    &:hover {
      color: white !important;
    }
  }
}
</style>
