import Vue from 'vue'
import Vuex from 'vuex'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'
import createPersistedState from 'vuex-persistedstate'
import makeCrudModule from './modules/crud'
import modules from './modules'
import { makeService } from '@services/api'

import { SITE_SLUG } from '@constants/siteDetails'

Vue.use(Vuex)

let persistedPaths = ['auth.currentUser']

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState({ paths: persistedPaths })],
})

store.registerModule(
  'locationCrud',
  makeCrudModule({
    service: makeService(`/path-to-model/${SITE_SLUG}/locations`),
  })
)

let addlModules = ['regions', 'elements']
addlModules.map((entity) => {
  store.registerModule(
    entity,
    makeCrudModule({
      // TODO (ESS) : this isn't a real API endpoint
      service: makeService(`/path-to-model/${SITE_SLUG}/${entity}`),
    })
  )
})

export default store

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
