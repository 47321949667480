<template>
  <Layout>
    <BasePageSection bg-color="#cfebdf">
      <VLayout row wrap justify-center>
        <h1 class="r-title pa-4">
          The page timed out while loading.
        </h1>
        <p>Please make sure you're still connected to the web and try again.</p>
      </VLayout>
    </BasePageSection>
  </Layout>
</template>

<script>
// TODO (ESS) : replace with 'main' layout after TDB launch
import Layout from '@utils/get-layout'

export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
  components: { Layout },
}
</script>

<style lang="scss" module>
.r-title {
  text-align: center;
}
</style>
