import fuzzy from 'fuzzysearch'

function matchText(targetText, searchString) {
  return fuzzy(searchString.toLowerCase(), targetText.toLowerCase())
} // matchText

function fuzzyMatch(collection, targetKey, searchString) {
  return collection.filter((a) => {
    let targetText =
      typeof targetKey === 'function' ? targetKey(a) : a[targetKey]
    return matchText(targetText, searchString)
  })
} // fuzzyMatch

export default fuzzyMatch
