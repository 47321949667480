// This module is for managing site config and theming

import api from '@services/api'
import {
  CACHE_SITE,
  SET_API_OFFLINE,
  SET_API_ONLINE,
  UPDATE_SITE_CONTENT,
} from '@constants/mutations'
import { THEME_NAME, SITE_SLUG } from '@constants/siteDetails'

import _get from 'lodash/get'

export const state = {
  fb_url: null,
  ig_access_token: null,
  ig_url: null,
  legal_name: null,
  location_summary: [],
  site_domain: null,
  id: null,
  slug: SITE_SLUG,
  theme_name: THEME_NAME,
  title: null,
  twitter_url: null,
  show_covid_alert: false,
  show_global_popup: false,
  show_global_banner: false,
  isApiOffline: false,
  contents: {},
  nav_menu: [],
}

export const getters = {
  getSite: (state) => state,
  getSiteField: (state) => (key, defaultVal) => _get(state, key, defaultVal),
  getSiteId: (state, getters) => getters.getSiteField('id'),
  getSiteNavMenu: (state, getters) => getters.getSiteField('nav_menu'),
  getSocialChannels: (state) => {
    let channels = []
    if (state.ig_url) {
      channels.push({
        name: 'instagram',
        url: state.ig_url,
      })
    }
    if (state.fb_url) {
      channels.push({
        name: 'facebook',
        url: state.fb_url,
      })
    }
    if (state.twitter_url) {
      channels.push({
        name: 'twitter',
        url: state.twitter_url,
      })
    }

    return channels
  },
  getLegalName: (state, getters) =>
    getters.getSiteField('legal_name') || getters.getSiteField('title'),
  getSiteTitle: (state, getters) => getters.getSiteField('title'),
  getSiteSlug: (state, getters) => getters.getSiteField('slug'),
  getThemeName: (state, getters) => getters.getSiteField('theme_name'),
  getIgAccessToken: (state, getters) => getters.getSiteField('ig_access_token'),
  getShowGlobalNotice: (state, getters) =>
    !!getters.getSiteField('show_covid_alert', false),
  getShowGlobalPopup: (state, getters) =>
    !!getters.getSiteField('show_global_popup', false),
  getShowGlobalBanner: (state, getters) =>
    !!getters.getSiteField('show_global_banner', false),
  getLocationSummary: (state, getters) =>
    getters.getSiteField('location_summary'),
  isApiOffline: (state) => state.isApiOffline,
  getSiteContents: (state, getters) => getters.getSiteField('contents'),
  getSiteContentByKey: (state, getters) => (key, defaultVal) =>
    _get(getters.getSiteContents, key, defaultVal),
}

export const mutations = {
  [CACHE_SITE](state, newSite) {
    let keysToUpdate = Object.keys(state).filter((k) => k !== 'isApiOffline')
    keysToUpdate.forEach((key) => {
      state[key] = _get(newSite, key)
    })
  },
  [UPDATE_SITE_CONTENT](state, { key, value }) {
    state.contents[key] = value
  }, // UPDATE_SITE_CONTENT

  [SET_API_OFFLINE](state) {
    state.isApiOffline = true
  },
  [SET_API_ONLINE](state) {
    state.isApiOffline = false
  },
}

export const actions = {
  async fetchSite({ commit, getters }, { siteSlug }) {
    const matchedSite = getters.getSite
    // EDS - is this the right way to test and see if the site has loaded?
    if (matchedSite && matchedSite.title) {
      return matchedSite
    }
    // Fetch the site from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(`/path-to-model/${siteSlug}`)
      const site = response.data
      commit(SET_API_ONLINE)
      commit(CACHE_SITE, site)

      return site
    } catch (err) {
      commit(SET_API_OFFLINE)
      return Error(err)
    }
  }, // fetchSite

  async updateSiteContent({ commit, getters }, { key, value }) {
    try {
      const response = await api.patch(
        `contents/site/${getters.getSiteId}/${key}`,
        { value }
      )

      const content = response.data

      commit(UPDATE_SITE_CONTENT, { key, value })

      return content
    } catch (err) {
      return Promise.reject(err)
    }
  }, // updateSiteContent
}
