<template>
  <div :class="`page-container-${page.slug}`">
    <component
      :is="dynamicComponent"
      v-if="dynamicComponent"
      v-bind="{ ...$attrs, ...$props }"
      v-on="$listeners"
      @hook:mounted="$emit('mounted')"
    >
      <slot />
    </component>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PageTemplate',
  props: {
    page: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      dynamicComponent: null,
      resolvedPath: '',
    }
  },
  computed: {
    ...mapGetters('site', ['getThemeName']),
    pageSlug() {
      return this.page.slug || ''
    }, // pageSlug
    customThemeLoader() {
      if (!this.pageSlug.length) {
        return null
      }
      // console.log(`Trying custom theme component for ${this.customPagePath}`)
      return () => import(`@themes/${this.customPagePath}`)
    },
    defaultThemeLoader() {
      if (!this.pageSlug.length) {
        return null
      }
      // console.log(`Trying default component for ${this.pageSlug}`)
      return () => import(`@restoBaseTheme/${this.pagePath}`)
    },

    pagePath() {
      return `page-templates/${this.pageSlug}`
    }, // pagePath

    customPagePath() {
      return `${this.getThemeName}/${this.pagePath}`
    }, // customPagePath()
  },
  mounted() {
    this.customThemeLoader()
      .then(() => {
        this.dynamicComponent = () => this.customThemeLoader()
        this.resolvedPath = `@themes/${this.customPagePath}`
      })
      .catch(() => {
        this.defaultThemeLoader().then(() => {
          this.dynamicComponent = () => this.defaultThemeLoader()
          this.resolvedPath = `@restoBaseTheme/page-templates/default`
        })
      })
  },
}
</script>
