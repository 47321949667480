<script>
// import Tests from '@store/Tests'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithLayoutRegion',
  props: {
    regionId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isRegionLoading: false,
    }
  },
  computed: {
    ...mapGetters('layouts', ['getRegion']),
    theRegion() {
      return this.isRegionLoading ? {} : (this.getRegion(this.regionId) || {})
    }, // theRegion
  },
  watch: {
    regionId: {
      async handler(val) {
        if (!this.regionId) {
          return
        }
        this.isRegionLoading = true
        await this.fetchRegion({
          regionId: this.regionId,
        })
        this.isRegionLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('layouts', ['fetchRegion']),
  },
  render() {
    return this.$scopedSlots.default({
      theRegion: this.theRegion,
      isRegionLoading: this.isRegionLoading,
    })
  },
}
</script>
