/**
 * @fileoverview This will automatically register any components you place in
 * the components/Base directory as a global component, where the component name
 * is the file name prefixed with `Base`, e.g. components/Base/Spinner.vue will
 * be made globally available as BaseSpinner.
 */

import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  '.', // Look for files in the current directory
  true, // look in subdirectories
  // Inclue all .vue files in ./ directory
  /[\w-]+\.vue$/
)

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName)
  // Get the PascalCase version of the component name
  const componentName = upperFirst(
    camelCase(
      fileName
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
    )
  )

  // Globally register the component
  Vue.component(
    `Base${componentName}`,
    componentConfig.default || componentConfig
  )
})
