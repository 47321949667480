<template>
  <VSnackbar
    v-model="show"
    :top="['md', 'sm', 'xs'].includes($mq)"
    :right="!['md', 'sm', 'xs'].includes($mq)"
    :timeout="60000"
    class="editor-snackbar"
  >
    {{ editorSnackbarMessage }}
    <BaseButton
      class="ui"
      :color="isShowingEditUI ? 'orange' : 'green'"
      @click="toggleEditUI"
    >
      Turn {{ isShowingEditUI ? 'OFF' : 'ON' }}
    </BaseButton>
    <BaseButton class="ui" color="grey" outline @click="logOutAction"
      >Log Out</BaseButton
    >
    <!-- <BaseButtonIconMini
      class="ui ml-3"
      :color="isShowingTrashedItems ? 'green' : 'white'"
      :text-color="isShowingTrashedItems ? 'white' : 'green'"
      mini
      fab
      :outline="!isShowingTrashedItems"
      :md-icon="isShowingTrashedItems ? 'delete' : 'delete_outline'"
      :title="
        isShowingTrashedItems ? 'Hide Trashed Items' : 'Show Trashed Items'
      "
      @click="toggleTrashedEntities"
    /> -->
    <BaseButton class="ui" color="grey" flat @click="hide">&times;</BaseButton>
  </VSnackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { VSnackbar } from '@vuetify'
export default {
  name: 'EditorSnackbar',
  components: { VSnackbar },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('editor', [
      'isEditorSnackbarVisible',
      'editorSnackbarMessage',
      'isShowingEditUI',
      'isShowingTrashedItems',
    ]),
    show: {
      get() {
        return this.isEditorSnackbarVisible
      },
      set(val) {
        this.setEditorSnackbarVisibility(val)
      },
    },
  }, // computed
  methods: {
    ...mapActions('editor', [
      'setEditorSnackbarVisibility',
      'toggleEditUI',
      'toggleTrashedEntities',
    ]),
    ...mapActions('auth', ['logOut']),
    hide() {
      this.setEditorSnackbarVisibility(false)
    }, // hide
    logOutAction() {
      this.hide()
      this.logOut()
    }, // logOutAction
  }, // methods
}
</script>
