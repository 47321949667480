<template>
  <component
    :is="dynamicComponent"
    v-if="dynamicComponent"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
    @hook:mounted="$emit('mounted')"
  >
    <slot />
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ThemeLayout',
  props: {},
  data() {
    return {
      dynamicComponent: null,
      resolvedPath: '',
    }
  },
  computed: {
    ...mapGetters('site', ['getThemeName']),
    customThemeLoader() {
      return () => import(`@themes/${this.customThemePath}`)
    },
    defaultThemeLoader() {
      return () =>
        import(`@restoBaseTheme/components/Layouts/${this.layoutPath}`)
    },

    // TODO (ESS) : convert this to a vuex getter
    getLayoutType() {
      return 'Web'
    }, // getLayoutType

    layoutPath() {
      return `${this.getLayoutType}Layout`
    }, // layoutPath

    defaultThemePath() {
      return `layouts/${this.layoutPath}`
    }, // defaultThemePath()

    customThemePath() {
      return `${this.getThemeName}/Layouts/${this.layoutPath}`
    }, // customThemePath()
  },
  mounted() {
    this.customThemeLoader()
      .then(() => {
        this.dynamicComponent = () => this.customThemeLoader()
        this.resolvedPath = `@themes/${this.customThemePath}`
      })
      .catch(() => {
        this.defaultThemeLoader()
          .then(() => {
            this.dynamicComponent = () => this.defaultThemeLoader()
            this.resolvedPath = `@restoBaseTheme/${this.defaultThemePath}`
          })
          .catch((err) => {
            this.dynamicComponent = () => new Error(err)
          })
      })
  },
}
</script>
