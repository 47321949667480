<template>
  <Draggable
    v-model="layoutElements"
    :class="[
      'layout-elements',
      isShowingLayoutEditUI
        ? 'layout-elements--active'
        : 'layout-elements--hiding-ui',
      {
        'layout-elements--empty': empty,
        'drag-target': isTargetDropZone,
      },
    ]"
    group="elements"
    :disabled="!isShowingEditUI"
    handle=".drag-handle"
    :move="moveFn"
    @end="endFn"
  >
    <div v-if="!layoutElements.length" class="text-xs-center placeholder"
      >Drag Items Here</div
    >

    <template v-for="(element, e) in layoutElements" v-else>
      <div :key="element.id || e" class="layout-element--draggable">
        <WithLayoutElement
          v-if="element.id"
          v-slot="{ isElementLoading, theElement }"
          :key="element.id"
          :element-id="element.id"
        >
          <template v-if="!isElementLoading && theElement">
            <LayoutElement
              :element="theElement"
              :ratio="ratio"
              :font-size="fontSize"
              @hook:mounted="loaded++"
            />
          </template>
        </WithLayoutElement>
        <LayoutElement
          v-else
          :element="element"
          :ratio="ratio"
          :font-size="fontSize"
          @hook:mounted="loaded++"
        />
      </div>
    </template>
  </Draggable>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _get from 'lodash/get'
import Draggable from 'vuedraggable'
import LayoutElement from './LayoutElement'
import WithLayoutElement from '@dataProviders/WithLayoutElement'

export default {
  name: 'LayoutElementsList',
  components: {
    Draggable,
    LayoutElement,
    WithLayoutElement,
  },
  props: {
    regionId: {
      type: [String, Number],
      default: null,
    },
    elements: {
      type: Array,
      required: true,
      default: () => [],
    },
    ratio: {
      type: Number,
      default: 1,
    },
    fontSize: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      loaded: 0,
    }
  },
  computed: {
    ...mapGetters('layoutEditor', [
      'isShowingLayoutEditUI',
      'targetDropZoneId',
    ]),
    ...mapGetters('editor', ['isShowingEditUI']),
    empty() {
      return !this.elements.length
    },
    layoutElements: {
      get() {
        return this.elements
      },
      set(list) {
        this.$emit('changed', list)
      },
    },
    isTargetDropZone() {
      return this.regionId === this.targetDropZoneId
    }, // isTargetDropZone
    hasLoadedAllElements() {
      return this.loaded >= this.layoutElements.length
    },
  }, // computed
  watch: {
    hasLoadedAllElements(hasLoadedAllElements) {
      if (hasLoadedAllElements) {
        this.$nextTick(() => {
          this.$emit('doneLoading')
        })
      }
    },
  }, // watch
  methods: {
    ...mapActions('layoutEditor', ['setTargetDropZone']),
    moveFn(evt) {
      let evtTargetId = _get(evt, 'relatedContext.element.id')
      if (evtTargetId) {
        this.setTargetDropZone(evtTargetId)
      } else if (_get(evt, 'relatedContext.component.$parent.regionId')) {
        this.setTargetDropZone(evt.relatedContext.component.$parent.regionId)
      }
    }, // moveFn
    endFn() {
      this.setTargetDropZone(null)
    }, // endFn
  }, // methods
}
</script>

<style lang="scss" scoped>
.layout-elements {
  &.layout-elements--active {
    height: 100%;
    .layout-element--draggable {
      cursor: grab;
    }
  }
}
.layout-elements--empty {
  display: flex;
  align-items: center;

  &.layout-elements--hiding-ui {
    .placeholder {
      display: none;
    }
  }
  &.layout-elements--active {
    justify-content: center;
    border: 1px dotted hsl(0deg, 0%, 80%);
    .placeholder {
      font-size: 1rem;
      line-height: 1.1;
      color: hsl(0deg, 0%, 50%);
      text-transform: uppercase;
      letter-spacing: 0.04em;
    }
  }
  &.drag-target {
    .placeholder {
      display: none;
    }
  }
  &.layout-elements--active {
    min-height: 100px;
  }
} // .layout-elements--empty
</style>
