<template>
  <VSnackbar
    v-model="isAuthSnackbarVisible"
    bottom
    :timeout="0"
    class="auth-snackbar"
  >
    You are currently logged out.
    <BaseButton color="pink" outline @click="showAuthModal">
      Login
    </BaseButton>
    <BaseButton color="pink" flat @click="hideAuthSnackbar">
      Close
    </BaseButton>
  </VSnackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { VSnackbar } from '@vuetify'
export default {
  name: 'AuthSnackbar',
  components: { VSnackbar },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('auth', ['isAuthSnackbarVisible']),
  }, // computed
  methods: {
    ...mapActions('auth', ['hideAuthSnackbar', 'showAuthModal']),
  }, // methods
}
</script>
