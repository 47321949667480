<template>
  <BaseButtonOutline
    v-if="outlineBtn"
    v-bind="{ ...$attrs, ...$props }"
    class="chownow-order-online"
    v-on="$listeners"
    @click="chownowPopup"
    ><slot
  /></BaseButtonOutline>
  <BaseButton
    v-else
    v-bind="{ ...$attrs, ...$props }"
    class="chownow-order-online"
    v-on="$listeners"
    @click="chownowPopup"
    ><slot
  /></BaseButton>
</template>

<script>
import Button from '@base/Button'
import ButtonOutline from '@base/ButtonOutline'
export default {
  name: 'ButtonChowNow',
  props: {
    ...ButtonOutline.props,
    ...Button.props,
    outlineBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    chownowPopup() {
      let isBig = ['md', 'lg', 'xl'].includes(this.$mq)
      if (isBig && window.ChowNow) {
        window.ChowNow.popup()
      } else {
        window.open('https://ordering.chownow.com/order/8485/locations')
      }
    }, // chownowPopup
  },
}
</script>
