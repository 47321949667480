<template>
  <VContainer class="menu" fluid pa-0>
    <template v-for="menuId of menuIds">
      <WithMenu
        :id="menuId"
        v-slot="{ isMenuLoading, menu }"
        :key="`menu-${menuId}`"
      >
        <BaseMenuComponent
          name="MenuLayout"
          :menu="menu"
          @dirtyUpdate="adjustEditorDirtScore"
        >
          <VLayout v-if="!isMenuLoading" row wrap>
            <template v-for="sectionId of menu.sections">
              <slot :sectionId="sectionId">
                <VFlex :key="`section-${sectionId}`" xs12 grow>
                  <WithMenuSection
                    :id="sectionId"
                    v-slot="{ isSectionLoading, section, itemMods, myMods }"
                  >
                    <BaseMenuComponent
                      v-if="section"
                      name="MenuSection"
                      :entity="section"
                      :item-mods="itemMods"
                      :my-mods="myMods"
                    />
                  </WithMenuSection>
                </VFlex>
              </slot>
            </template>
          </VLayout>
        </BaseMenuComponent>
      </WithMenu>
    </template>
  </VContainer>
</template>

<script>
import WithMenuSection from '@dataProviders/WithMenuSection'
import WithMenu from '@dataProviders/WithMenu'
import { mapActions } from 'vuex'

export default {
  name: 'LocationMenus',
  components: {
    WithMenuSection,
    WithMenu,
  },
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    menuIds() {
      return this.location.menus || []
    }, // menuIds
  },
  methods: {
    ...mapActions('editor', ['adjustEditorDirtScore']),
  },
}
</script>
