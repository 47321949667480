<template>
  <VParallax
    v-if="parallax && bgImage"
    dark
    :src="bgImage"
    class="page-section"
    v-bind="allPropsAndAttrs"
  >
    <h2 v-if="title" class="header-3 text-xs-center page-section--title">{{
      title
    }}</h2>
    <VLayout row align-center>
      <VFlex class="text-xs-center section-content">
        <VContainer :style="contentStyles">
          <slot />
        </VContainer>
      </VFlex>
    </VLayout>
  </VParallax>
  <!-- TODO (ESS) : turn this into a "FullBleedBgImg" component -->
  <BlockSplitHalf
    v-else-if="type === 'split-half'"
    v-bind="allPropsAndAttrs"
    :class="[
      'page-section',
      { 'has-bg': bgImage },
      { 'has-bg-color': bgColor },
    ]"
    :content-styles="contentStyles"
    :section-styles="sectionStyles"
  >
    <template v-for="(index, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
  </BlockSplitHalf>
  <BlockDefault
    v-else
    v-bind="allPropsAndAttrs"
    :class="[
      'page-section',
      { 'has-bg': bgImage },
      { 'has-bg-color': bgColor },
    ]"
    :content-styles="contentStyles"
    :section-styles="sectionStyles"
  >
    <template v-for="(index, name) in $slots" #[name]>
      <slot :name="name" />
    </template>
  </BlockDefault>
</template>

<script>
import { VParallax } from '@vuetify'

export default {
  name: 'PageSection',
  components: { VParallax },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: '',
    },
    bgColor: {
      type: [String, Boolean],
      default: false,
    },
    bgImage: {
      type: [String, Boolean],
      default: '',
    },
    bgGradient: {
      type: [String, Boolean],
      default: '',
    },
    contentBgColor: {
      type: String,
      default: '',
    },
    parallax: {
      type: Boolean,
      default: false,
    },
    fullBleedBg: {
      type: Boolean,
      default: false,
    },
    fullBleedContent: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    fullWidthContent: {
      type: Boolean,
      default: false,
    },
    hasOverlay: {
      type: Boolean,
      default: false,
    },
    overlayColor: {
      type: String,
      default: '#000000',
    },
  },
  data() {
    return {}
  },
  computed: {
    sectionStyles() {
      let styles = {
        ...(this.bgColor && { 'background-color': this.bgColor }),
        ...(this.bgImage && {
          'background-image': `url('${this.bgImage}')`,
        }),
        ...(this.bgImage && { 'background-size': 'cover' }),
        ...(this.bgImage && { 'background-position': 'center center' }),
        ...(this.fullBleedBg && { 'max-width': '100%' }),
      }
      if (this.bgGradient) {
        styles['background'] = this.bgGradient
      }
      return styles
    }, // sectionStyles
    contentStyles() {
      let styles = {
        ...(this.contentBgColor && {
          'background-color': this.contentBgColor,
        }),
        ...(this.fullWidth && { 'max-width': '100%' }),
      }
      return styles
    }, // contentStyles
    allPropsAndAttrs() {
      return { ...this.$props, ...this.$attrs }
    }, // allPropsAndAttrs
  },
}
</script>

<style lang="scss">
@use "sass:math";
@import '@design';

.page-section {
  position: relative;
  padding: $size-grid-padding * 2;

  @media (max-width: 960px) {
    padding: $size-grid-padding * 2 math.div($size-grid-padding, 2);
  }

  @media (max-width: 600px) {
    padding: $size-grid-padding * 2 math.div($size-grid-padding, 4);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.25;
  }
}

.page-section--title {
  line-height: 0.85 !important;
}

.section-content {
  z-index: 2;
  padding: math.div($size-grid-padding, 2) math.div($size-grid-padding, 2);
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: math.div($size-grid-padding, 4) math.div($size-grid-padding, 4);
  }

  .not-sm {
    padding: $size-grid-padding $size-grid-padding * 2;
  }
  > .container {
    text-align: left;

    &.fluid {
      @media only screen and (min-width: 1540px) {
        max-width: 1440px;
      }
    }

    @media only screen and (min-width: 1904px) {
      max-width: 1440px;
    }
  }
}
</style>
