<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithMenu',
  props: {
    id: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  data() {
    return {
      isMenuLoading: false,
    }
  },
  computed: {
    ...mapGetters('menus', ['getMenu']),
    menu() {
      return this.isMenuLoading ? {} : this.getMenu(this.id) || {}
    }, // menu
  },
  watch: {
    id: {
      async handler(val) {
        this.isMenuLoading = true
        await this.fetchMenu({
          menuId: this.id,
        })
        this.isMenuLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('menus', ['fetchMenu']),
  },
  render() {
    return this.$scopedSlots.default({
      isMenuLoading: this.isMenuLoading,
      menu: this.menu,
    })
  },
}
</script>
