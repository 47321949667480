// Authentication mutations.
export const USER_LOG_IN = 'USER_LOG_IN'
export const USER_LOG_OUT = 'USER_LOG_OUT'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR'
export const USER_REFRESH = 'USER_REFRESH'
export const SET_SIGNIN_REDIRECT = 'SET_SIGNIN_REDIRECT'
export const SET_REFRESH_INTERVAL_ID = 'SET_REFRESH_INTERVAL_ID'
export const SET_LOGOUT_INTERVAL_ID = 'SET_LOGOUT_INTERVAL_ID'
export const CLEAR_INTERVAL = 'CLEAR_INTERVAL'
export const TOGGLE_AUTH_SNACKBAR = 'TOGGLE_AUTH_SNACKBAR'
export const SET_AUTH_SNACKBAR = 'SET_AUTH_SNACKBAR'
export const SET_AUTH_MODAL = 'SET_AUTH_MODAL'

// Editor
export const TOGGLE_EDIT_UI = 'TOGGLE_EDIT_UI'
export const SET_EDIT_UI = 'SET_EDIT_UI'
export const SET_EDITOR_SNACKBAR_VISIBILITY = 'SET_EDITOR_SNACKBAR_VISIBILITY'
export const SET_EDITOR_SNACKBAR_MSG = 'SET_EDITOR_SNACKBAR_MSG'
export const ADJUST_DIRT_SCORE = 'ADJUST_DIRT_SCORE'
export const TOGGLE_TRASHED_ENTITIES = 'TOGGLE_TRASHED_ENTITIES'

// Drag & Drop
export const DRAG_START = 'DRAG_START'
export const DRAG_END = 'DRAG_END'
export const SET_THE_DRAG_OBJECT = 'SET_THE_DRAG_OBJECT'
export const SET_DRAGGING_STATE = 'SET_DRAGGING_STATE'

// API Management
export const SET_API_OFFLINE = 'SET_API_OFFLINE'
export const SET_API_ONLINE = 'SET_API_ONLINE'

// Path/Route/View Management
export const SET_PATH_EDITABILITY = 'SET_PATH_EDITABILITY'

// Site Object
export const CACHE_SITE = 'CACHE_SITE'
export const UPDATE_SITE_CONTENT = 'UPDATE_SITE_CONTENT'

// Menu Management
export const CACHE_MENU = 'CACHE_MENU'
export const SET_MENUS = 'SET_MENUS'
export const UPDATE_ENTITY_FROM_RESPONSE = 'UPDATE_ENTITY_FROM_RESPONSE'
export const UPDATE_CHILD_ENTITIES = 'UPDATE_CHILD_ENTITIES'

export const MOD_ENTITY = 'MOD_ENTITY'
export const MAKE_MENU_MOD = 'MAKE_MENU_MOD'
export const REMOVE_ENTITY_MOD = 'REMOVE_ENTITY_MOD'
export const REMOVE_ALL_ENTITY_MODS = 'REMOVE_ALL_ENTITY_MODS'
export const TOGGLE_RELATED_ENTITY = 'TOGGLE_RELATED_ENTITY'
export const REMOVE_RELATED_ENTITY_MOD = 'REMOVE_RELATED_ENTITY_MOD'

// Add & Remove Entities
export const CREATE_ENTITY = 'CREATE_ENTITY'
export const TRASH_ENTITY = 'TRASH_ENTITY'
export const UNTRASH_ENTITY = 'UNTRASH_ENTITY'
export const REMOVE_ENTITY = 'REMOVE_ENTITY'
export const UPDATE_ENTITY = 'UPDATE_ENTITY'
export const SET_ENTITY = 'SET_ENTITY'
export const ENTITY_STAGE_REMOVE = 'ENTITY_STAGE_REMOVE'
export const ADD_CHILD_ENTITY = 'ADD_CHILD_ENTITY'
export const REMOVE_CHILD_ENTITY = 'REMOVE_CHILD_ENTITY'
export const UPDATE_CHILD_ID = 'UPDATE_CHILD_ID'
export const STAGE_NEW_CHILD_ENTITY = 'STAGE_NEW_CHILD_ENTITY'
export const REMOVE_STAGED_CHILD_ENTITY = 'REMOVE_STAGED_CHILD_ENTITY'

// New & Updated Entities
export const SET_ENTITIES = 'SET_ENTITIES'
export const MERGE_ENTITIES = 'MERGE_ENTITIES'
export const MOD_NEW_ENTITY = 'MOD_NEW_ENTITY'

// Sections
export const UPDATE_SECTION_ITEMS = 'UPDATE_SECTION_ITEMS'

// Items

// Snackbar
export const SET_MENU_SNACKBAR = 'SET_MENU_SNACKBAR'

// Pages
export const CACHE_PAGE = 'CACHE_PAGE'
export const CACHE_PAGE_CONTENT = 'CACHE_PAGE_CONTENT'
export const SET_THE_PAGE = 'SET_THE_PAGE'

// Locations
export const CACHE_LOCATION = 'CACHE_LOCATION'
export const CACHE_LOCATION_CONTENT = 'CACHE_LOCATION_CONTENT'
export const SET_THE_LOCATION = 'SET_THE_LOCATION'

// Layouts
export const CACHE_LAYOUT = 'CACHE_LAYOUT'
export const CACHE_LAYOUT_FIELD = 'CACHE_LAYOUT_FIELD'
export const CACHE_REGION = 'CACHE_REGION'
export const CACHE_ELEMENT = 'CACHE_ELEMENT'

// LayoutEditor
export const TOGGLE_LAYOUT_EDIT_UI = 'TOGGLE_LAYOUT_EDIT_UI'
export const SET_LAYOUT_EDIT_UI = 'SET_LAYOUT_EDIT_UI'
export const UPDATE_ELEMENT_SEARCH = 'UPDATE_ELEMENT_SEARCH'
export const SET_TARGET_DROP_ZONE = 'SET_TARGET_DROP_ZONE'

// Nav

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'
export const SET_DRAWER = 'SET_DRAWER'
export const TOGGLE_GLOBAL_NOTICE = 'TOGGLE_GLOBAL_NOTICE'
export const SET_GLOBAL_POPUP_VISIBILITY = 'SET_GLOBAL_POPUP_VISIBILITY'
export const SET_GLOBAL_BANNER_VISIBILITY = 'SET_GLOBAL_BANNER_VISIBILITY'
export const HIDE_EMAIL_MODAL = 'HIDE_EMAIL_MODAL'
export const SHOW_EMAIL_MODAL = 'SHOW_EMAIL_MODAL'
