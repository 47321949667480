import {
  TOGGLE_LAYOUT_EDIT_UI,
  SET_LAYOUT_EDIT_UI,
  UPDATE_ELEMENT_SEARCH,
  SET_TARGET_DROP_ZONE,
} from '@constants/mutations'

export const state = {
  isShowingLayoutEditUI: false,
  elementSearch: null,
  targetDropZoneId: null,
}

export const mutations = {
  [TOGGLE_LAYOUT_EDIT_UI](state) {
    state.isShowingLayoutEditUI = !state.isShowingLayoutEditUI
  }, // TOGGLE_LAYOUT_EDIT_UI
  [SET_LAYOUT_EDIT_UI](state, visibility) {
    state.isShowingLayoutEditUI = visibility
  }, // SET_LAYOUT_EDIT_UI
  [UPDATE_ELEMENT_SEARCH](state, q) {
    state.elementSearch = q
  }, // UPDATE_ELEMENT_SEARCH
  [SET_TARGET_DROP_ZONE](state, regionId) {
    state.targetDropZoneId = regionId
  }, // SET_TARGET_DROP_ZONE
}

export const getters = {
  isShowingLayoutEditUI: (state) => state.isShowingLayoutEditUI,
  elementSearch: (state) => state.elementSearch,
  targetDropZoneId: (state) => state.targetDropZoneId,
  isInMenuEditMode: (state, _, rootState) => rootState.editor.isShowingEditUI,
}

export const actions = {
  toggleLayoutEditUI({ commit }) {
    commit(TOGGLE_LAYOUT_EDIT_UI)
  }, // toggleLayoutEditUI
  setLayoutEditUI({ commit }, visibility) {
    commit(SET_LAYOUT_EDIT_UI, visibility)
  }, // setLayoutEditUI
  updateElementSearch({ commit }, query) {
    commit(UPDATE_ELEMENT_SEARCH, query)
  }, // updateElementSearch
  setTargetDropZone({ commit }, regionId) {
    commit(SET_TARGET_DROP_ZONE, regionId)
  }, // setTargetDropZone
} // actions
