<template>
  <WithSite v-slot="{ isSiteLoading }">
    <transition name="fade" appear>
      <BaseLoader v-if="isSiteLoading" bg-color="#FFF" color="#BBB" />
      <VApp v-else id="app" :class="`theme--${THEME_NAME}`">
        <OfflineAlert v-if="isApiOffline" />
        <RouterView v-else :key="$route.fullPath" />
      </VApp>
    </transition>
  </WithSite>
</template>

<script>
import { THEME_NAME } from '@constants/siteDetails'
import { mapGetters } from 'vuex'
import OfflineAlert from '@components/OfflineAlert'
export default {
  page() {
    // All subcomponent titles will be injected into this template.
    const siteTitle = this.getSiteTitle

    return {
      titleTemplate(viewTitle) {
        viewTitle =
          typeof viewTitle === 'function' ? viewTitle(this.$store) : viewTitle

        return viewTitle ? `${viewTitle} | ${siteTitle}` : siteTitle
      },
    }
  },
  components: {
    OfflineAlert,
  },
  data() {
    return {
      THEME_NAME,
    }
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle', 'isApiOffline']),
  },
}
</script>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
@use "sass:math";

// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/design.
@import '@design';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $color-body-bg;
}

html {
  font-size: 16px;
}

// ===
// Base element styles
// ===

a {
  color: $color-link-text;
}

img {
  max-width: 100%;
}

// ===
// Vendor
// ===

#nprogress .bar {
  background: $color-link-text;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $duration-animation-base;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: $duration-animation-base * 3;
}
.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity $duration-animation-base;
}
.fade-fast-enter,
.fade-fast-leave-active {
  opacity: 0;
}
.fade-fast-enter-active {
  transition-delay: $duration-animation-base;
}
.fade-fastest-enter-active,
.fade-fastest-leave-active {
  transition: opacity math.div($duration-animation-base, 2);
}
.fade-fastest-enter,
.fade-fastest-leave-active {
  opacity: 0;
}
.fade-fastest-enter-active {
  transition-delay: math.div($duration-animation-base, 2);
}
</style>
