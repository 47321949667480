<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'WithLocation',
  props: {
    slug: {
      type: [String, Boolean],
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLocationLoading: false,
      notFound: null,
    }
  },
  computed: {
    ...mapGetters('locations', ['getLocationBySlug', 'getLocation']),
    ...mapGetters('site', ['getLegalName', 'getSiteId']),
    theLocation() {
      return this.id
        ? this.getLocation(this.id)
        : this.getLocationBySlug(this.computedSlug) || {}
    }, // theLocation
    computedSlug() {
      return this.slug || this.$route.params.locSlug
    }, // computedSlug
    theFeaturedImage() {
      return this.theLocation.featured_image
    }, // theFeaturedImage
  },
  watch: {
    slug: {
      async handler(val) {
        this.isLocationLoading = true
        try {
          if (this.id) {
            await this.fetchLocationById({ locId: this.id })
          } else {
            await this.fetchLocation({ locSlug: this.computedSlug })
          }
        } catch (error) {
          if (error.status === 404) {
            this.notFound = true
          }
        } finally {
          this.isLocationLoading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('locations', ['fetchLocation', 'fetchLocationById']),
  },
  render() {
    return this.$scopedSlots.default({
      theLocation: this.theLocation,
      isLocationLoading: this.isLocationLoading,
      theFeaturedImage: this.theFeaturedImage,
      notFound: this.notFound,
    })
  },
}
</script>
