<template>
  <div class="menu-"></div>
</template>

<script>
export default {
  name: 'Menu',
  components: {},
  data() {
    return {}
  },
}
</script>
