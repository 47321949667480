import {
  CACHE_PAGE,
  CACHE_PAGE_CONTENT,
  SET_THE_PAGE,
} from '@constants/mutations'
import { SITE_SLUG } from '@constants/siteDetails'

import api from '@services/api'
import _get from 'lodash/get'
import Vue from 'vue'

export const state = {
  cachedPages: {},
  thePage: null,
}

export const getters = {
  getPageBySlug: (state) => (pageSlug) => {
    return Object.values(state.cachedPages).find(
      (p) => p.slug === pageSlug && p.site.slug === SITE_SLUG
    )
  },
  getPage: (state) => (pageId) => state.cachedPages[pageId],
  getThePage: (state) => state.thePage,
  getThePageId: (state) => (state.thePage ? state.thePage.id : null),
  getPageContent: (state, getters) => (key) =>
    _get(getters.getThePage, `contents[${key}]`, undefined),
}

export const mutations = {
  [CACHE_PAGE](state, newPage) {
    Vue.set(state.cachedPages, newPage.id, newPage)
  },
  [CACHE_PAGE_CONTENT](state, { pageId, key, value }) {
    Vue.set(state.cachedPages[pageId].contents, key, value)
  },
  [SET_THE_PAGE](state, thePage) {
    state.thePage = thePage
  },
}

export const actions = {
  async fetchPage({ commit, getters, rootState }, { pageSlug }) {
    // 1. Check if we've already fetched and cached the page.
    const matchedPage = await getters.getPageBySlug(pageSlug)
    if (matchedPage) {
      commit(SET_THE_PAGE, matchedPage)
      return matchedPage
    }
    // 2. Fetch the page from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(`/path-to-model/${SITE_SLUG}/${pageSlug}`)

      const page = response.data

      commit(CACHE_PAGE, page)
      commit(SET_THE_PAGE, page)
      return page
    } catch (err) {
      return err
    }
  },
  async updatePageContent({ commit }, { pageId, key, value }) {
    try {
      const response = await api.patch(`contents/page/${pageId}/${key}`, {
        value,
      })

      const content = response.data

      commit(CACHE_PAGE_CONTENT, { pageId, key, value })

      return content
    } catch (err) {
      return Promise.reject(err)
    }
  },
}
