<template>
  <component
    :is="componentType"
    v-if="componentType"
    :element="element"
    :ratio="ratio"
    :font-size="fontSize"
    :class="`layout-element--${element.type}`"
    :tag="element.content.tag || null"
    :tag-class="tagClass"
  />
</template>

<script>
import LayoutElementTypeText from './LayoutElementTypeText'
import LayoutElementTypeImage from './LayoutElementTypeImage'
import LayoutElementTypeRelated from './LayoutElementTypeRelated'
import _get from 'lodash/get'

export default {
  name: 'LayoutElement',
  components: {
    LayoutElementTypeText,
    LayoutElementTypeImage,
    LayoutElementTypeRelated,
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {},
    },
    ratio: {
      type: Number,
      default: 1,
    },
    fontSize: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {}
  },
  computed: {
    componentType() {
      switch (this.element.type) {
        case 'text':
          return 'LayoutElementTypeText'
        case 'image':
          return 'LayoutElementTypeImage'
        case 'related':
          return 'LayoutElementTypeRelated'
      }

      return null
    },
    tagClass() {
      return _get(this.element, `content['tag-class']`, '')
    },
  },
}
</script>
