import Vue from 'vue'
import api from '@services/api'
import {
  CREATE_ENTITY,
  STAGE_NEW_CHILD_ENTITY,
  REMOVE_STAGED_CHILD_ENTITY,
  ADD_CHILD_ENTITY,
  REMOVE_CHILD_ENTITY,
  UPDATE_CHILD_ENTITIES,
  UPDATE_CHILD_ID,
  SET_ENTITIES,
  MERGE_ENTITIES,
  ENTITY_STAGE_REMOVE,
  REMOVE_ENTITY,
  MOD_ENTITY,
  REMOVE_ENTITY_MOD,
  MOD_NEW_ENTITY,
  TOGGLE_RELATED_ENTITY,
  REMOVE_RELATED_ENTITY_MOD,
  REMOVE_ALL_ENTITY_MODS,
  UPDATE_ENTITY_FROM_RESPONSE,
} from '@constants/mutations'
import { ENTITY_PARENT_NAMES, ENTITY_NAMES } from '@constants/lookupTables'

import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'
import _pull from 'lodash/pull'
import _setWith from 'lodash/setWith'
import _forEach from 'lodash/forEach'
import _isEqual from 'lodash/isEqual'
import _difference from 'lodash/difference'
import _union from 'lodash/union'

export const state = {
  entities: {},
  mods: {},
}

export const mutations = {
  //
  // Add / Remove Entities
  //
  [CREATE_ENTITY](state, { entityName, newEntity }) {
    Vue.set(state.entities[entityName], newEntity.id, newEntity)
  }, // CREATE_ENTITY

  [STAGE_NEW_CHILD_ENTITY](
    state,
    { entityName, entityId, parentEntityName, parentId, index }
  ) {
    // check if the "childEntities" mod already exists
    let existingChildEntityMods = _get(
      state.mods,
      `${parentEntityName}.${parentId}.${entityName}`,
      false
    )

    // if not, start with a copy of the existing normal child entities
    let updatedChildEntityMods
    if (existingChildEntityMods) {
      updatedChildEntityMods = existingChildEntityMods
    } else {
      let existingChildEntities = _get(
        state.entities[parentEntityName][parentId],
        entityName,
        []
      )
      updatedChildEntityMods = [...existingChildEntities]
    }

    // modify to include the new entity
    if (index === 0) {
      updatedChildEntityMods.unshift(entityId)
    } else if (!index) {
      updatedChildEntityMods.push(entityId)
    } else {
      updatedChildEntityMods.splice(index, 0, entityId)
    }

    let existingEntityMods = { ...state.mods[parentEntityName] }
    let existingItemMods = { ...existingEntityMods[parentId] }
    Vue.set(state.mods, parentEntityName, {
      ...existingEntityMods,
      [parentId]: {
        ...existingItemMods,
        [entityName]: updatedChildEntityMods,
      },
    })
  }, // STAGE_NEW_CHILD_ENTITY

  [REMOVE_STAGED_CHILD_ENTITY](
    state,
    { entityName, entityId, parentEntityName, parentId }
  ) {
    // check if the "childEntities" mod already exists
    let existingChildEntityMods = _get(
      state.mods,
      `${parentEntityName}.${parentId}.${entityName}`,
      false
    )
    // short-circuit if there are no staged children to remove
    // (they should be stored on the parentEntity.mods object
    if (!existingChildEntityMods) {
      return false
    }

    // copy array then remove the target index
    let updatedChildEntityMods = [...existingChildEntityMods]
    updatedChildEntityMods.splice(updatedChildEntityMods.indexOf(entityId), 1)

    let existingEntityMods = { ...state.mods[parentEntityName] }
    let existingItemMods = { ...existingEntityMods[parentId] }
    if (updatedChildEntityMods.length > 0) {
      Vue.set(state.mods, parentEntityName, {
        ...existingEntityMods,
        [parentId]: {
          ...existingItemMods,
          [entityName]: updatedChildEntityMods,
        },
      })
    } else {
      Vue.delete(state.mods[parentEntityName][parentId], entityName)
    }
  }, // REMOVE_STAGED_CHILD_ENTITY

  [ADD_CHILD_ENTITY](
    state,
    { entityName, entityId, parentEntityName, parentId, index }
  ) {
    let updatedChildEntities = _get(
      state.entities[parentEntityName][parentId],
      entityName,
      []
    )
    if (index === 0) {
      updatedChildEntities.unshift(entityId)
    } else if (!index) {
      updatedChildEntities.push(entityId)
    } else {
      updatedChildEntities.splice(index, 0, entityId)
    }
    Vue.set(
      state.entities[parentEntityName][parentId],
      entityName,
      updatedChildEntities
    )
  }, // ADD_CHILD_ENTITY

  [REMOVE_CHILD_ENTITY](
    state,
    { entityName, entityId, parentEntityName, parentId }
  ) {
    let updatedChildEntities = _get(
      state.entities[parentEntityName][parentId],
      entityName,
      []
    )
    Vue.set(
      state.entities[parentEntityName][parentId],
      entityName,
      updatedChildEntities.filter((id) => id !== entityId)
    )
  }, // REMOVE_CHILD_ENTITY

  [UPDATE_CHILD_ENTITIES](
    state,
    { parentEntityName, parentId, entityName, children }
  ) {
    Vue.set(state.entities[parentEntityName][parentId], entityName, children)
  }, // UPDATE_CHILD_ENTITIES

  [UPDATE_CHILD_ID](state, { entityName, stagingId, newId, parentEntityName }) {
    // get all parent entities that have the original ID in the target child collection
    let parentEntities = _pickBy(
      state.entities[parentEntityName],
      (parentEntity, parentId) => {
        return parentEntity[entityName].includes(stagingId)
      }
    )
    parentEntities = Object.keys(parentEntities).map((parentId) => {
      let updatedChildren = [...parentEntities[parentId][entityName]]
      updatedChildren.splice(updatedChildren.indexOf(stagingId), 1, newId)
      Vue.set(
        state.entities[parentEntityName][parentId],
        entityName,
        updatedChildren
      )
    })
  }, // UPDATE_CHILD_ID

  [ENTITY_STAGE_REMOVE](state, { entityName, entityToRemove, parentId }) {
    Vue.set(state.entities[entityName], entityToRemove.id, {
      ...entityToRemove,
      removedFromParent: parentId,
      isTrashed: true,
    })
  }, // ENTITY_STAGE_REMOVE

  [REMOVE_ENTITY](state, { entityName, entityId }) {
    let updatedCollection = { ...state.entities[entityName] }
    updatedCollection = _pickBy(
      updatedCollection,
      (e) => String(e.id) !== String(entityId)
    )
    Vue.set(state.entities, entityName, updatedCollection)
  }, // REMOVE_ENTITY

  [MOD_ENTITY](state, { entityName, entityId, modKey, modVal }) {
    let existingEntityMods = { ...state.mods[entityName] }
    let existingItemMods = { ...existingEntityMods[entityId] }
    Vue.set(state.mods, entityName, {
      ...existingEntityMods,
      [entityId]: {
        ...existingItemMods,
        [modKey]: modVal,
      },
    })
  }, // MOD_ENTITY

  [UPDATE_ENTITY_FROM_RESPONSE](state, { entityName, entityId, newContent }) {
    Vue.set(state.entities, entityName, {
      ...state.entities[entityName],
      [entityId]: newContent,
    })
  }, // UPDATE_ENTITY_FROM_RESPONSE

  //
  // Modify New Entities
  //
  [MOD_NEW_ENTITY](state, { entityName, entityId, modKey, modVal }) {
    let updatedEntity = { ...state.entities[entityName][entityId] }
    updatedEntity[modKey] = modVal
    Vue.set(state.entities[entityName], entityId, updatedEntity)
  }, // MOD_NEW_ENTITY

  [REMOVE_ENTITY_MOD](state, { entityName, entityId, modKey }) {
    // Short circuit if the key doesnt exist
    // (in an instance where we do a comparison and
    //  think the original content === new content, we try to
    // remove the mod node even if it doesn't exist)
    let modExists = _get(
      state.mods,
      `${entityName}.${entityId}.${modKey}`,
      false
    )
    if (!modExists) {
      return true
    }
    Vue.delete(state.mods[entityName][entityId], modKey)
    // If there aren't any more mods on this entity.id, remove it
    if (!Object.keys(state.mods[entityName][entityId]).length) {
      Vue.delete(state.mods[entityName], entityId)
    }
    // If there aren't any more mods on this entity remove it
    if (!Object.keys(state.mods[entityName]).length) {
      Vue.delete(state.mods, entityName)
    }
  }, // REMOVE_MENU_MOD

  [REMOVE_ALL_ENTITY_MODS](state, { entityName, entityId }) {
    // Short circuit if the key doesnt exist
    // (in an instance where we do a comparison and
    //  think the original content === new content, we try to
    // remove the mod node even if it doesn't exist)
    let modsExist = _get(state.mods, `${entityName}.${entityId}`, false)
    if (!modsExist) {
      return true
    }
    Vue.delete(state.mods[entityName], entityId)

    // If there aren't any more mods on this entity remove it
    if (!Object.keys(state.mods[entityName]).length) {
      Vue.delete(state.mods, entityName)
    }
  }, // REMOVE_ALL_ENTITY_MODS

  //
  // Related Data Mods
  //

  [TOGGLE_RELATED_ENTITY](
    state,
    { attachOrDetach, entityName, id, relatedEntity, relatedId }
  ) {
    let updatedMods = { ...state.mods }
    let inverseAction = attachOrDetach === 'attach' ? 'detach' : 'attach'

    let relatedEntities = _get(
      updatedMods,
      `${entityName}[${id}][${attachOrDetach}ments][${relatedEntity}]`,
      []
    )

    let inverseActionEntities = _get(
      updatedMods,
      `${entityName}[${id}][${inverseAction}ments][${relatedEntity}]`,
      []
    )
    if (inverseActionEntities.includes(relatedId)) {
      _pull(inverseActionEntities, relatedId)
      _setWith(
        updatedMods,
        `${entityName}[${id}][${inverseAction}ments][${relatedEntity}]`,
        inverseActionEntities,
        Object
      )
      // If there aren't any more mods on this entity.id, remove it
      if (!inverseActionEntities.length) {
        Vue.delete(
          updatedMods[entityName][id][`${inverseAction}ments`],
          relatedEntity
        )
      }
      // If there aren't any more mods on this entity attach/detach, remove it
      if (
        !Object.keys(updatedMods[entityName][id][`${inverseAction}ments`])
          .length
      ) {
        Vue.delete(updatedMods[entityName][id], `${inverseAction}ments`)
      }

      // If there aren't any more mods on this entity remove it
      if (!Object.keys(updatedMods[entityName][id]).length) {
        Vue.delete(updatedMods[entityName], id)
      }

      if (!Object.keys(updatedMods[entityName]).length) {
        Vue.delete(updatedMods, entityName)
      }

      Vue.set(state, 'mods', updatedMods)
      return false
    }

    if (relatedEntities.includes(relatedId)) {
      // eslint-disable-next-line
      console.log(`${relatedId} already ${attachOrDetach}ed!`)
      return false
    }
    relatedEntities.push(relatedId)
    _setWith(
      updatedMods,
      `${entityName}[${id}][${attachOrDetach}ments][${relatedEntity}]`,
      relatedEntities,
      Object
    )
    Vue.set(state, 'mods', updatedMods)
  }, // TOGGLE_RELATED_ENTITY

  [REMOVE_RELATED_ENTITY_MOD](
    state,
    { attachOrDetach, entityName, entityId, relatedEntity, relatedId }
  ) {
    let newMods = [
      ...state.mods[entityName][entityId][`${attachOrDetach}ments`][
        relatedEntity
      ],
    ]
    newMods.splice(newMods.indexOf(relatedId), 1)
    Vue.set(
      state.mods[entityName][entityId][`${attachOrDetach}ments`],
      relatedEntity,
      newMods
    )
  }, // REMOVE_RELATED_ENTITY_MOD
}

export const getters = {
  getAllMods: (state) => state.mods,

  getEntity: (state, _, rootState) => (entityName, id) =>
    _get(rootState[entityName].entities[id], id, {}),

  getEntitySet: (state, _, rootState) => (entityName, ids) =>
    !ids ? [] : ids.map((id) => rootState[entityName].entities[id]),

  getNonRemovedEntitySet: (state) => (entityName, ids) =>
    ids
      .map((id) => state.entities[entityName][id])
      .filter((e) => e && !e.isTrashed),

  getAllOfEntity: (state, _, rootState) => (entityName) =>
    _get(rootState, `${entityName}.entities`, {}),

  getAllModsForEntity: (state) => (entityName) =>
    _get(state.mods, entityName, {}),

  getEntityMods: (state) => (entityName, id) =>
    _get(state.mods, `${entityName}.${id}`, false),

  getEntityOriginalContent: (state) => (entityName, id) =>
    _get(state.entities, `${entityName}.${id}`, false),

  getEntityOriginalAttachments: (state) => (entityName, id, relatedEntity) =>
    _get(state.entities, `${entityName}.${id}.${relatedEntity}`, []),

  getRelatedEntitySetWithMods: (state, getters) => (
    entityName,
    id,
    relatedEntity
  ) => {
    // get original relatedEntity IDs
    let relatedIdsWithMods =
      getters.getEntityOriginalContent(entityName, id)[relatedEntity] || []
    let attachments = getters.getEntityAttachmentMods(
      entityName,
      id,
      relatedEntity
    )
    let detachments = getters.getEntityDetachmentMods(
      entityName,
      id,
      relatedEntity
    )
    // subtract detachments
    let updatedMods = _difference(relatedIdsWithMods, detachments)
    // add attachments
    updatedMods = _union(updatedMods, attachments)
    // get related entities by new array
    return getters.getEntitySet(relatedEntity, updatedMods)
  }, // getRelatedEntitySetWithMods

  getEntityAttachmentMods: (state) => (entityName, id, relatedEntity) =>
    _get(state.mods, `${entityName}.${id}.attachments[${relatedEntity}]`, []),

  getEntityDetachmentMods: (state) => (entityName, id, relatedEntity) =>
    _get(state.mods, `${entityName}.${id}.detachments[${relatedEntity}]`, []),

  getAllNewEntities: (state) => {
    let newEntities = {}
    Object.keys(state.entities).map((entityName) => {
      let filteredByNew = _pickBy(state.entities[entityName], (entity, key) => {
        return entity.isNew
      })
      if (Object.keys(filteredByNew).length) {
        newEntities[entityName] = filteredByNew
      }
    })
    return newEntities
  }, // getAllNewEntities

  getNewEntities: (state, getters) => (entityName) => {
    let newEntities = getters.getAllNewEntities
    return _get(newEntities, entityName, false)
  }, // getNewEntities

  getRemovedEntities: (state) => {
    let removedEntities = {}
    Object.keys(state.entities).map((entityName) => {
      let filteredByRemoved = _pickBy(
        state.entities[entityName],
        (entity, key) => {
          return entity.isTrashed
        }
      )
      if (Object.keys(filteredByRemoved).length) {
        removedEntities[entityName] = filteredByRemoved
      }
    })
    return removedEntities
  }, // getRemovedEntities

  getChildEntityIds: (state) => (
    parentEntityName,
    parentId,
    childEntityName
  ) => {
    let moddedChildren = _get(
      state.mods,
      `${parentEntityName}.${parentId}.${childEntityName}`,
      []
    )
    // return the mods or the DB-verified children
    return moddedChildren.length
      ? moddedChildren
      : _get(
          state.entities,
          `${parentEntityName}.${parentId}.${childEntityName}`,
          []
        )
  }, // getChildEntityIds,
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, getters }) {},
  async tryEntityMod(
    { commit, dispatch, getters, rootGetters },
    { entityName, entityId, mods }
  ) {
    let originalContent = getters.getEntityOriginalContent(entityName, entityId)
    _forEach(mods, function(modVal, modKey) {
      if (modKey === 'prices') {
        modVal = [...modVal].map((price) => {
          return isNaN(price) || typeof price === 'string'
            ? price
            : parseFloat(price)
        })
        // Update the parent object with sanitized val
        mods[modKey] = modVal
      }
      if (
        _isEqual(modVal, originalContent[modKey]) ||
        rootGetters['menus/isEmptySectionItemMod'](entityName, modVal, modKey)
      ) {
        commit(REMOVE_ENTITY_MOD, { entityName, entityId, modKey })
      } else {
        commit(MOD_ENTITY, { entityName, entityId, modKey, modVal })
      }
    })

    // dispatch('liveEditMenuMod', { entityName, entityId, mods })
  }, // tryEntityMod

  modNewEntity({ commit, dispatch, getters }, { entityName, entityId, mods }) {
    let originalContent = getters.getEntity(entityName, entityId)
    _forEach(mods, function(modVal, modKey) {
      if (modKey === 'prices') {
        modVal = [...modVal].map((price) => {
          return isNaN(price) || typeof price === 'string'
            ? price
            : parseFloat(price)
        })
        // Update the parent object with sanitized val
        mods[modKey] = modVal
      }
      if (!_isEqual(modVal, originalContent[modKey])) {
        commit(MOD_NEW_ENTITY, { entityName, entityId, modKey, modVal })
      }
    })
  }, // modNewEntity

  removeAllEntityMods({ commit }, { entityName, entityId }) {
    commit(REMOVE_ALL_ENTITY_MODS, { entityName, entityId })
  }, // removeAllEntityMods

  removeEntityMod({ commit }, { entityName, entityId, modKey }) {
    commit(REMOVE_ENTITY_MOD, { entityName, entityId, modKey })
  }, // removeAllEntityMods

  async patchEntity(
    { commit, getters, dispatch },
    { objRoute, entityId, mods }
  ) {
    dispatch('SET_CHANGES_UNSAVED', null, { root: true })
    try {
      let res = await api.patch(`/${objRoute}/${entityId}`, mods)
      // eslint-disable-next-line
      console.log({ res })
      // commit(SET_CHANGES_SAVED)
      return res
    } catch (error) {
      Promise.reject(error)
    }
  }, // patchEntity

  async stageNewEntity({ commit, dispatch }, { newEntity, entityName }) {
    commit(CREATE_ENTITY, { entityName, newEntity })
    dispatch('tryMenuMod', {
      entityName,
      entityId: newEntity.id,
      mods: { ...newEntity },
    })
  }, // stageNewEntity

  async stageNewChildEntity(
    { commit },
    { newEntity, entityName, parentEntityName, parentId, index = undefined }
  ) {
    commit(CREATE_ENTITY, { entityName, newEntity })
    commit(STAGE_NEW_CHILD_ENTITY, {
      entityName,
      entityId: newEntity.id,
      parentEntityName,
      parentId,
      index: typeof index !== 'undefined' ? index : false,
    })
  }, // stageNewChildEntity

  async removeEntityFromCollection(
    { commit, dispatch, getters },
    { parentEntityName, parentId, entityName, entityId }
  ) {
    let targetChildEntity = getters.getEntity(entityName, entityId)
    commit(REMOVE_ENTITY, { entityName, entityId })
    // remove "staged deletion" entity or
    // Remove child entity from parent collection
    let mutationType = targetChildEntity.isNew
      ? REMOVE_STAGED_CHILD_ENTITY
      : REMOVE_CHILD_ENTITY
    commit(mutationType, {
      entityName,
      entityId,
      parentEntityName,
      parentId,
    })
  }, // removeEntityFromCollection

  async createChildEntity(
    { commit, getters },
    { newEntity, entityName, entityId, childEntityName, index = undefined }
  ) {
    let entityRoute = getters.getObjRouteFromEntity(entityName)
    let childEntityRoute = getters.getObjRouteFromEntity(childEntityName)
    api
      .createAndAttachEntity({
        entityRoute,
        entityId,
        childEntityRoute,
        newEntity,
      })
      .then((res) => {
        // dispatch('updateEntityFromResponse', {
        //   res,
        //   entityName,
        //   entityId,
        // })
        // dispatch('removeRelatedEntityMod', {
        //   attachOrDetach: 'attach',
        //   entityName,
        //   entityId,
        //   relatedEntity,
        //   relatedId,
        // })
      })
      .catch((err) => {
        Promise.reject(err)
      })
  }, // createChildEntity

  removeRemovedEntity({ commit }, { entityName, entityId }) {
    commit(REMOVE_ENTITY, { entityName, entityId })
  }, // removeRemovedEntity

  removeEntity(
    { commit, getters, dispatch },
    { entityName, entityId, parentEntityName, parentId }
  ) {
    let entityToRemove = getters.getEntity(entityName, entityId)
    if (entityToRemove.isNew) {
      // if item is new, get rid of it
      dispatch('removeEntityFromCollection', {
        entityName,
        entityId,
        parentEntityName,
        parentId,
      })
    } else {
      // if item is not new, mark it for deletion
      commit(ENTITY_STAGE_REMOVE, {
        entityName,
        entityToRemove,
        parentId,
      })
    }
  }, // removeEntity

  async tryToDetachRelatedEntity(
    { commit, dispatch, getters, rootGetters },
    { entityName, id, relatedEntity, relatedId }
  ) {
    commit(TOGGLE_RELATED_ENTITY, {
      attachOrDetach: 'detach',
      entityName,
      id,
      relatedEntity,
      relatedId,
    })
  }, // tryToDetachRelatedEntity

  removeRelatedEntityMod(
    { commit },
    { attachOrDetach, entityName, entityId, relatedEntity, relatedId }
  ) {
    commit(REMOVE_RELATED_ENTITY_MOD, {
      attachOrDetach,
      entityName,
      entityId,
      relatedEntity,
      relatedId,
    })
  }, // removeRelatedEntityMod

  async publishModifiedEntities({ commit, getters, dispatch }) {
    let mods = getters.getAllMods
    await Object.keys(mods).map(async (entityName) => {
      let entityMods = mods[entityName]
      await Object.keys(entityMods).map(async (entityId) => {
        try {
          let objRoute = getters.getObjRouteFromEntity(entityName)
          let filteredMods = _pickBy(
            entityMods[entityId],
            (modVal, modKey) => !['attachments', 'detachments'].includes(modKey)
          )
          if (!Object.keys(filteredMods).length) {
            Promise.resolve()
          }
          let res = await dispatch('patchEntity', {
            objRoute,
            entityId,
            mods: filteredMods,
          })
          if (entityName === ENTITY_NAMES.SECTIONS) {
            await dispatch('updateSectionFromResponse', {
              res,
              sectionId: entityId,
            })
            await dispatch('removeAllEntityMods', {
              entityName,
              entityId,
            })
          } else {
            dispatch('updateEntityFromResponse', {
              res,
              entityName,
              entityId,
            })
            await Object.keys(filteredMods).map(async (modKey) => {
              commit(REMOVE_ENTITY_MOD, { entityName, entityId, modKey })
            })
          }
        } catch (error) {
          Promise.reject(error)
        }
      })
    })
  }, // publishModifiedEntities

  async publishNewEntities({ getters, dispatch }) {
    let newGuys = getters.getAllNewEntities
    _forEach(newGuys, async (collection, entityName) => {
      if (entityName === ENTITY_NAMES.ITEMS) {
        return false
      }
      _forEach(collection, async (entity, stagingId) => {
        try {
          let res = await api.createEntity(entity)
          dispatch('udpateParentItem', {
            entityName,
            stagingId,
            newId: res.data.id,
            parentEntityName: ENTITY_PARENT_NAMES[entityName],
          })
          // replace "staged" new entity with real response from db
          dispatch('replaceTempEntityWithResponse', {
            res,
            entityName,
            stagingId,
            newId: res.data.id,
          })
        } catch (err) {
          Promise.reject(err)
        }
      })
    })
  }, // publishNewEntities

  updateEntityFromResponse({ commit, getters }, { res, entityName, entityId }) {
    let normalizeFn = getters.getNormalizeFunctionFromEntity(entityName)
    let normalizedContent = normalizeFn(res.data)
    let newContent = normalizedContent.entities[entityName][entityId]
    commit(UPDATE_ENTITY_FROM_RESPONSE, { entityName, entityId, newContent })
  }, // updateEntityFromResponse

  setAllEntities({ commit, dispatch }, entities) {
    Object.keys(entities).map((entity) => {
      commit(`${entity}/${SET_ENTITIES}`, entities[entity], {
        root: true,
      })
    })
  }, // setAllEntities

  mergeAllEntities({ commit }, entities) {
    Object.keys(entities).map((entity) => {
      commit(`${entity}/${MERGE_ENTITIES}`, entities[entity], {
        root: true,
      })
    })
  }, // mergeAllEntities
} // actions
