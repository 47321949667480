import Vue from 'vue'
import api from '@services/api'
import { getField, updateField } from 'vuex-map-fields'
import {
  SET_AUTH_SNACKBAR,
  TOGGLE_AUTH_SNACKBAR,
  SET_CURRENT_USER,
  USER_REFRESH,
  SET_AUTH_MODAL,
} from '@constants/mutations'

export const state = {
  // currentUser: getSavedState('auth.currentUser'),
  // currentUser: {
  //   email: 'admin@joinresto.com',
  //   token: 'zLg5QEkdQNs2TgyGHuB62I4xE45ano6shKqEba2taU5JTCk1trX1ASMLuV7c',
  // },
  autosave: false,
  isAuthSnackbarVisible: false,
  isAuthModalVisible: false,
  isLoginSuccessSnackbarVisible: false,
}

export const mutations = {
  [SET_CURRENT_USER](state, newValue) {
    Vue.set(state, 'currentUser', newValue)
    saveState('auth.currentUser', newValue)
    setDefaultAuthHeaders(state)
  },

  [USER_REFRESH](state, userData) {
    let updatedUser = { ...state.currentUser, ...userData }
    Vue.set(state, 'currentUser', updatedUser)
    saveState('auth.currentUser', updatedUser)
    setDefaultAuthHeaders(state)
  },

  [TOGGLE_AUTH_SNACKBAR](state) {
    state.isAuthSnackbarVisible = !state.isAuthSnackbarVisible
  }, // TOGGLE_AUTH_SNACKBAR

  [SET_AUTH_SNACKBAR](state, isVisible) {
    state.isAuthSnackbarVisible = isVisible
  },

  [SET_AUTH_MODAL](state, isVisible) {
    state.isAuthModalVisible = isVisible
  },
  updateField,
}

export const getters = {
  loggedIn: (state) => !!state.currentUser && !!state.currentUser.token,
  getUser: (state) => state.currentUser,

  isAuthSnackbarVisible: (state) => state.isAuthSnackbarVisible,
  isAuthModalVisible: (state) => state.isAuthModalVisible,
  getField,
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    setDefaultAuthHeaders(state)
    // dispatch('validate')
  },

  // Logs in the current user.
  logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    return api.post('/login', { email, password }).then((response) => {
      const user = response.data
      commit(SET_CURRENT_USER, user)
      dispatch('hideAuthSnackbar')
      return user
    })
  },

  // Logs out the current user.
  logOut({ commit, dispatch }) {
    commit(SET_CURRENT_USER, null)
    dispatch('showAuthSnackbar')
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)

    return api
      .get('/validate-token')
      .then((response) => {
        const user = response.data
        commit(USER_REFRESH, user)
        return Promise.resolve(true)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          commit(SET_CURRENT_USER, null)
        }
        return Promise.reject(error)
      })
  },

  loginOrToggleUI({ dispatch, getters }) {
    if (getters.loggedIn) {
      dispatch('editor/toggleEditUI', null, { root: true })
    } else {
      dispatch('toggleAuthSnackbar')
    }
  }, // loginOrToggleUI

  toggleAuthSnackbar({ commit }) {
    commit(TOGGLE_AUTH_SNACKBAR)
  }, // toggleAuthSnackbar

  showAuthSnackbar({ commit }) {
    commit(SET_AUTH_SNACKBAR, true)
  }, // showAuthSnackbar

  hideAuthSnackbar({ commit }) {
    commit(SET_AUTH_SNACKBAR, false)
  }, // hideAuthSnackbar

  showAuthModal({ commit, dispatch }) {
    dispatch('hideAuthSnackbar')
    commit(SET_AUTH_MODAL, true)
  }, // showAuthModal

  hideAuthModal({ commit }) {
    commit(SET_AUTH_MODAL, false)
  }, // hideAuthModal
} // actions

// ===
// Private helpers
// ===

// function getSavedState(key) {
//   return JSON.parse(window.localStorage.getItem(key))
// }

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  api.defaults.headers.common.Authorization = state.currentUser
    ? `Bearer ${state.currentUser.token}`
    : ''
}
