<script>
// import TestPages from '@store/TestPages'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithPage',
  props: {
    slug: {
      type: [String, Boolean],
      default: false,
    },
  },
  data() {
    return {
      isPageLoading: false,
    }
  },
  computed: {
    ...mapGetters('pages', ['getPageBySlug']),
    thePage() {
      return this.getPageBySlug(this.slug) || {}
    }, // thePage
  },
  watch: {
    slug: {
      async handler(val) {
        this.isPageLoading = true
        await this.fetchPage({
          pageSlug: this.slug || this.$route.params.pageSlug,
        })
        this.isPageLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('pages', ['fetchPage']),
  },
  render() {
    return this.$scopedSlots.default({
      thePage: this.getPageBySlug(this.slug) || false,
      isPageLoading: this.isPageLoading,
    })
  },
}
</script>
