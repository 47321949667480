<template>
  <VSnackbar
    v-model="isLoginSuccessSnackbarVisible"
    bottom
    :timeout="5000"
    class="login-success-snackbar"
    color="blue darken-1"
    vertical
    @click="isLoginSuccessSnackbarVisible = false"
  >
    <h2 class="mb-2">Welcome Back! 👋</h2>
    <h3 class="mb-3">Login Successful</h3>
  </VSnackbar>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

import { VSnackbar } from '@vuetify'
export default {
  name: 'LoginSuccessSnackbar',
  components: { VSnackbar },
  data() {
    return {}
  },
  computed: {
    ...mapFields('auth', ['isLoginSuccessSnackbarVisible']),
  }, // computed
}
</script>
