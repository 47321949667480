<template>
  <Layout>
    <Transition appear>
      <BaseLoader />
    </Transition>
  </Layout>
</template>

<script>
// TODO (ESS) : replace with 'main' layout after TDB launch
import Layout from '@utils/get-layout'
export default {
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
  components: { Layout },
}
</script>
