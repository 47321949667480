import { ENTITY_NAMES } from '@constants/lookupTables'

export const state = {}

export const mutations = {}

export const getters = {
  getCreateRoute: (state, getters, rootState, rootGetters) => (itemId) => {
    let getRouteFn = rootGetters['menus/getObjRouteFromEntity']
    let itemsRoute = getRouteFn(ENTITY_NAMES.ITEMS)
    let addonsRoute = getRouteFn(ENTITY_NAMES.ADDONS)
    return `${itemsRoute}/${itemId}/${addonsRoute}`
  }, // getCreateRoute

  getItemAddons: (state, getters, rootState, rootGetters) => (itemId) =>
    rootGetters['menus/getAllOfEntity'](ENTITY_NAMES.ADDONS).filter(
      (addon) => addon.item_id === itemId
    ), // getItemAddonSet
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, getters }) {},

  createItemAddon() {}, // createItemAddon

  updateItemAddon() {}, // updateItemAddon

  removeItemAddon() {}, // removeItemAddon

  // async stageItemAddon({ dispatch, rootGetters }, { itemId, sectionId }) {
  //   let addon = await rootGetters['addons/getNewAddon'](itemId)
  //   let item = rootGetters['items/getItem'](itemId)
  //   let itemMods = rootGetters['items/getItemMods'](itemId)
  //   let updatedAddons = itemMods.addons
  //     ? [...itemMods.addons]
  //     : [...item.addons]
  //   updatedAddons.push(addon.id)
  //   await dispatch(
  //     'menus/stageNewEntity',
  //     { newEntity: addon, entityName: ENTITY_NAMES.ADDONS },
  //     { root: true }
  //   )
  //   this.trySectionItemMod({
  //     sectionId: item.menu_section_id,
  //     item,
  //     key : ENTITY_NAMES.ADDONS,
  //     val : updatedAddons,
  //     sortedItems: this.sortedItems,
  //   })
  // }, // stageItemAddon
} // actions
