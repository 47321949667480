<template>
  <VContainer
    v-bind="allPropsAndAttrs"
    class="page-section page-section-image-break has-bg"
    :style="sectionStyles"
    fluid
    px-0
    my-0
    fill-height
  >
    <VFlex>
      <h2 v-if="!!title" :class="titleClass || 'header-3 text-xs-center'">{{
        title
      }}</h2>
    </VFlex>
  </VContainer>
</template>

<script>
export default {
  name: 'PageSectionImageBreak',
  components: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    titleClass: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
      default: '',
    },
    bgImage: {
      type: String,
      required: true,
      default: '',
    },
    minHeight: {
      type: String,
      required: false,
      default: '60vh',
    },
  },
  data() {
    return {}
  },
  computed: {
    sectionStyles() {
      let styles = {
        ...(this.bgColor.length && { 'background-color': this.bgColor }),
        ...(this.bgImage.length && {
          'background-image': `url('${this.bgImage}')`,
        }),
        ...(this.bgImage.length && { 'background-size': 'cover' }),
        ...(this.bgImage.length && { 'background-position': 'center center' }),
        'min-height': this.minHeight,
      }
      return styles
    }, // sectionStyles
    allPropsAndAttrs() {
      return { ...this.$props, ...this.$attrs }
    }, // allPropsAndAttrs
  },
}
</script>
