import { Extension } from 'tiptap'
export default class EnterKey extends Extension {
  keys() {
    return {
      Enter(state, dispatch, view) {
        // return true prevents default behaviour
        console.log('enter the dragon')
        return true
      },
    }
  }
}
