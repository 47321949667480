<template>
  <div v-if="!loggedIn || !isPathEditable" :class="['content--wrap']">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <component :is="tag" :class="tagClass" v-html="safeModifiedContent" />
  </div>
  <div v-else-if="isDeleted" :class="['save-as-deleted', tagClass]">
    <component :is="tag" @click="selectEmptyContent"
      >Save as Deleted
    </component>
    <BaseThemeComponent
      name="Editor/ButtonSaveContent"
      :loading="saving"
      @setHover="showHover = $event"
      @click="$emit('saveDelete', $event)"
    />
  </div>
  <div v-else-if="modifiedContent || isDirty" :class="['content--wrap']">
    <template v-if="modifiedContent">
      <BaseEditable
        ref="content"
        :tag="tagWithClasses"
        :content="content"
        :mods="modifiedContent"
        :class="['content', { selected }, { saving }, { hover: showHover }]"
        @toggleSelect="selected = $event"
        @update="updateContent($event)"
      />
      <transition name="fade-fast">
        <BaseThemeComponent
          v-if="isShowingEditUI && !selected && !isDirty"
          name="Editor/ButtonEditContent"
          @setHover="showHover = $event"
          @click="focusContent"
        />
        <BaseThemeComponent
          v-else-if="isDirty"
          name="Editor/ButtonSaveContent"
          :loading="saving"
          @setHover="showHover = $event"
          @click="saveContent"
        />
      </transition>
    </template>
  </div>
  <component
    :is="tag"
    v-else-if="loggedIn && isShowingEditUI"
    :class="['content-placeholder', tagClass]"
    @click="selectEmptyContent"
    >{{ placeholder }}</component
  >
</template>

<script>
import { mapGetters } from 'vuex'
import selectElementText from '@utils/select-element-text'
import TagClassMixin from '@mixins/tag-class'
import DOMPurify from 'dompurify'

export default {
  name: 'Content',
  components: {},
  mixins: [TagClassMixin],
  inheritAttrs: false,
  props: {
    contentKey: {
      type: String,
      required: true,
    },
    contentClass: {
      type: String,
      default: '',
    },
    defaultContent: {
      type: String,
      default: undefined,
    },
    content: {
      type: String,
      default: undefined,
    },
    contentableId: {
      type: Number,
      required: true,
      default: 0,
    },
    contentableType: {
      type: String,
      required: true,
      default: 'page',
    },
  },
  data() {
    return {
      modifiedContent: undefined,
      timeout: null,
      selected: false,
      saving: false,
      showHover: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('path', ['isPathEditable']),
    ...mapGetters('editor', ['isShowingEditUI']),
    showDefaultContent() {
      return typeof this.content === 'undefined' && !!this.defaultContent
    },
    safeModifiedContent() {
      return this.br2nl(DOMPurify.sanitize(this.modifiedContent))
    }, // safeModifiedContent
    isDirty() {
      if (
        typeof this.content === 'undefined' &&
        typeof this.modifiedContent === 'undefined'
      ) {
        return false
      }
      return (
        // 1. Doesn't have DB content and current val not equal to defaultContent
        (typeof this.content === 'undefined' &&
          this.modifiedContent !== this.defaultContent) ||
        // 2. Has DB content and current val not equal to db content
        (typeof this.content !== 'undefined' &&
          typeof this.modifiedContent !== 'undefined' &&
          this.modifiedContent !== this.content)
      )
    },
    isDeleted() {
      return this.isDirty && !this.modifiedContent
    }, // isDeleted
    keyFormatted() {
      return this.contentKey
        .split('_')
        .map((w) => this.formatWord(w))
        .join(' ')
    },
    placeholder() {
      return `Add ${this.keyFormatted}`
    }, // placeholder
  }, // computed
  watch: {
    contentableId: {
      handler() {
        this.initContent()
      },
      immediate: true,
    },
    loggedIn: {
      handler(isLoggedIn) {
        if (!isLoggedIn && this.saving) {
          this.saving = false
        }
      },
    },
  },
  mounted() {
    this.initContent()
  },
  methods: {
    async updateContent(value) {
      this.modifiedContent = value
    },

    initContent() {
      if (this.defaultContent && this.modifiedContent === this.defaultContent) {
        this.modifiedContent = this.defaultContent
      } else {
        this.modifiedContent = this.content
      }
    },
    selectEmptyContent() {
      this.modifiedContent = 'New ' + this.keyFormatted
      this.$nextTick(() => {
        this.focusContent()
        selectElementText(this.$refs['content'].$el)
      })
    },
    focusContent() {
      this.$refs['content'].$el.focus()
      this.selected = true
    },
    formatWord(word) {
      switch (word) {
        case 'ns':
          word = 'Narrative Section'
          break
        case 'img':
          word = 'Image'
          break
      }

      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    async saveContent() {
      this.saving = true
      await this.$emit('saveContent', this.modifiedContent)
      this.saving = false
    }, // saveContent
    br2nl(str) {
      return str && str.length ? str.replace(/<br\s*\/?>/gm, '\n') : str
    }, // br2nl
  }, // methods
}
</script>

<style lang="scss">
.content--wrap {
  position: relative;
  .edit-btn.v-btn--top {
    top: -12px;
    right: -25px;
  }
  .save-btn.v-btn--top {
    top: -30px;
    right: -30px;
  }
  > p,
  p.pm-el > p {
    white-space: pre-line;
  }
}

$hover-color: rgba(100, 245, 75, 0.2);

.content {
  opacity: 1;
  transition: opacity 0.3s ease, background-color 0.2s ease;

  &.is-dirty {
    &.hover {
      background-color: rgba(33, 150, 243, 0.3);
    }
  }
  &.saving {
    opacity: 0.4;
  }
}

.content:not(.selected),
.content-placeholder {
  cursor: pointer;
  &:hover,
  &.hover {
    background-color: $hover-color;
  }
}

/* stylelint-disable-next-line */
.content-placeholder {
  position: relative;
  z-index: 10;
  font-size: 0.8rem !important;
  font-style: italic;
  line-height: 1em;
  color: darken(white, 25%) !important;
}

.save-as-deleted {
  position: relative;
  width: 100%;
  font-style: italic;
  // font-size:.8rem !important;
  color: darken(white, 25%) !important;
}
</style>
