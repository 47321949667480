import {
  CACHE_LOCATION,
  SET_ENTITIES,
  MERGE_ENTITIES,
  CACHE_LOCATION_CONTENT,
  SET_THE_LOCATION,
} from '@constants/mutations'
import Vue from 'vue'
import { SITE_SLUG } from '@constants/siteDetails'

import { normalizeLocation } from '@utils/normalizr'
import _get from 'lodash/get'
import api from '@services/api'

export const state = {
  cachedLocations: {},
  entities: {},
  theLocation: null,
}

export const getters = {
  getLocationBySlug: (state) => (locSlug) => {
    let foundId = Object.keys(state.cachedLocations).find(
      (locId) => state.cachedLocations[locId].slug === locSlug
    )
    return foundId ? state.cachedLocations[foundId] : false
  },
  // state.cachedLocations.find((l) => l.slug === locSlug),
  getLocation: (state) => (locId) => state.cachedLocations[locId],
  getTheLocation: (state) => state.theLocation,
  getTheLocationId: (state) =>
    state.theLocation ? state.theLocation.id : null,
  getTheLocationContent: (state, getters) => (key) =>
    _get(getters.getTheLocation, `contents[${key}]`, undefined),
}

export const mutations = {
  [CACHE_LOCATION](state, loc) {
    Vue.set(state.cachedLocations, loc.id, loc)
    Vue.set(state.entities, loc.id, loc)
  },
  [CACHE_LOCATION_CONTENT](state, { locationId, key, value }) {
    Vue.set(state.cachedLocations[locationId].contents, key, value)
  },
  [SET_THE_LOCATION](state, theLocation) {
    state.theLocation = theLocation
  },
  [SET_ENTITIES](state, entities) {
    state.entities = entities
  },
  [MERGE_ENTITIES](state, updatedEntities) {
    state.entities = { ...state.entities, ...updatedEntities }
  },
}

export const actions = {
  async fetchLocation({ commit, getters, dispatch }, { locSlug }) {
    //   // 1. Check if we already have the user as a current user.
    //   const { currentUser } = rootState.auth
    //   if (currentUser && currentUser.username === username) {
    //     return Promise.resolve(currentUser)
    //   }
    // 2. Check if we've already fetched and cached the loc.
    const matchedLoc = await getters.getLocationBySlug(locSlug)
    if (matchedLoc) {
      commit(SET_THE_LOCATION, matchedLoc)
      return matchedLoc
    }
    // 3. Fetch the loc from the API and cache it in case
    //    we need it again in the future.
    try {
      const slug = SITE_SLUG
      const response = await api.get(
        `/path-to-model/${slug}/locations/${locSlug}`
      )

      let { entities, result } = normalizeLocation(response.data)
      const loc = entities.locations[result]

      commit(CACHE_LOCATION, loc)
      commit(SET_THE_LOCATION, loc)
      dispatch('entities/mergeAllEntities', entities, { root: true })
      return loc
    } catch (err) {
      return Promise.reject(err)
    }
  },
  async fetchLocationById({ commit, getters, dispatch }, { locId }) {
    //   // 1. Check if we already have the user as a current user.
    //   const { currentUser } = rootState.auth
    //   if (currentUser && currentUser.username === username) {
    //     return Promise.resolve(currentUser)
    //   }
    // 2. Check if we've already fetched and cached the loc.
    const matchedLoc = await getters.getLocation(locId)
    if (matchedLoc) {
      commit(SET_THE_LOCATION, matchedLoc)
      return matchedLoc
    }
    // 3. Fetch the loc from the API and cache it in case
    //    we need it again in the future.
    try {
      const response = await api.get(`/locations/${locId}`)

      let { entities, result } = normalizeLocation(response.data)
      const loc = entities.locations[result]

      commit(CACHE_LOCATION, loc)
      commit(SET_THE_LOCATION, loc)
      dispatch('entities/setAllEntities', entities, { root: true })
      return loc
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async updateLocationContent(
    { commit, getters, rootState },
    { locationId, key, value }
  ) {
    try {
      const response = await api.patch(
        `contents/location/${locationId}/${key}`,
        {
          value,
        }
      )

      const content = response.data

      commit(CACHE_LOCATION_CONTENT, { locationId, key, value })

      return content
    } catch (err) {
      return Promise.reject(err)
    }
  },
}
