import { getField, updateField } from 'vuex-map-fields'

import {
  TOGGLE_DRAWER,
  SET_DRAWER,
  TOGGLE_GLOBAL_NOTICE,
  SET_GLOBAL_POPUP_VISIBILITY,
  SET_GLOBAL_BANNER_VISIBILITY,
  HIDE_EMAIL_MODAL,
  SHOW_EMAIL_MODAL,
} from '@constants/mutations'
export const state = {
  isDrawerOpen: false,
  isGlobalNoticeOpen: true,
  isEmailModalOpen: false,
  isGlobalPopupOpen: true,
  isGlobalBannerOpen: true,
  isOrderDialogOpen: false,
  orderDialogSelectedLocation: null,
}

export const getters = {
  getDrawerState: (state) => state.isDrawerOpen, // getDrawerState
  getGlobalNoticeState: (state, _, __, rootGetters) =>
    state.isGlobalNoticeOpen && rootGetters['site/getShowGlobalNotice'], // getGlobalNoticeState
  getEmailModalState: (state) => state.isEmailModalOpen, // getEmailModalState
  getGlobalPopupState: (state, _, __, rootGetters) =>
    state.isGlobalPopupOpen && rootGetters['site/getShowGlobalPopup'], // getGlobalPopupState
  getGlobalBannerState: (state, _, __, rootGetters) =>
    state.isGlobalBannerOpen && rootGetters['site/getShowGlobalBanner'], // getGlobalBannerState
  getField,
}

export const mutations = {
  [TOGGLE_DRAWER](state) {
    state.isDrawerOpen = !state.isDrawerOpen
  },
  [SET_DRAWER](state, val) {
    state.isDrawerOpen = val
  },
  [TOGGLE_GLOBAL_NOTICE](state) {
    state.isGlobalNoticeOpen = !state.isGlobalNoticeOpen
  },
  [SET_GLOBAL_POPUP_VISIBILITY](state, isVisible) {
    state.isGlobalPopupOpen = isVisible
  },
  [SET_GLOBAL_BANNER_VISIBILITY](state, isVisible) {
    state.isGlobalBannerOpen = isVisible
  },
  [HIDE_EMAIL_MODAL](state) {
    state.isEmailModalOpen = false
  },
  [SHOW_EMAIL_MODAL](state) {
    state.isEmailModalOpen = true
  },
  updateField,
}

export const actions = {
  toggleGlobalNotice({ commit }) {
    commit(TOGGLE_GLOBAL_NOTICE)
  },
  showEmailModal({ commit }) {
    commit(SHOW_EMAIL_MODAL)
  },
  hideEmailModal({ commit }) {
    commit(HIDE_EMAIL_MODAL)
  },
  setGlobalPopup({ commit }, isVisible) {
    commit(SET_GLOBAL_POPUP_VISIBILITY, isVisible)
  },
  setGlobalBanner({ commit }, isVisible) {
    commit(SET_GLOBAL_BANNER_VISIBILITY, isVisible)
  },
}
