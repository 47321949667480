<template>
  <VDialog :value="show" :max-width="500" @update:returnValue="close">
    <VCard>
      <VCardTitle :class="[headerClass]">
        <VFlex xs12 ma-2
          ><h2 class="header-2 text-xs-center mb-0">
            Send Us a Message
          </h2></VFlex
        >
      </VCardTitle>
      <VCardText class="font-weight-medium pa-4">
        <BaseThemeComponent name="ContactForm" show-dismiss @dismiss="close" />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import VDialog from '@vuetify/VDialog'
import { VCardText } from '@vuetify/VCard'

export default {
  name: 'SendEmailModal',
  components: {
    VDialog,
    VCardText,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
      default: 'orange lighten-2 white--text',
    },
  },
  data() {
    return {
      toName: '',
      toEmail: '',
      emailSubject: '',
      emailBody: '',
    }
  },
  methods: {
    close(returnVal) {
      this.$emit('hide')
    }, // close
  },
}
</script>
