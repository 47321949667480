<template>
  <BaseThemeComponent
    :name="`Menu/${name}`"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'MenuComponent',
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
