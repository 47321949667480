<template>
  <div
    :id="`region-${region.id}`"
    class="region"
    :style="`grid-area: ${gridArea};margin:${12 * ratio}px`"
  >
    <LayoutElementsList
      :region-id="region.id"
      :ratio="ratio"
      :font-size="parseInt(fontSize, 10)"
      :elements="elements"
      @changed="listChanged"
      @doneLoading="$emit('doneLoading')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import LayoutElementsList from '@components/Layout/LayoutElementsList'

export default {
  name: 'LayoutRegion',
  components: { LayoutElementsList },
  props: {
    region: {
      type: Object,
      required: true,
    },
    fontSize: {
      type: [String, Number],
      required: true,
    },
    ratio: {
      type: [String, Number],
      required: true,
    },
    elements: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed: {
    gridArea() {
      return this.region.title
        .split(' ')
        .join('')
        .toLowerCase()
    },
  },
  methods: {
    ...mapActions('layouts', ['updateRegion']),
    listChanged(elements) {
      this.updateRegion({ regionId: this.region.id, data: { elements } })
    },
  },
}
</script>
<style lang="scss">
.region {
  .grid--editing & {
    // background: hsl(0deg,0deg, 95%);
  }
}
</style>
