import Vue from 'vue'
import api from '@services/api'

import { ENTITY_NAMES } from '@constants/lookupTables'

import {
  SET_ENTITIES,
  MERGE_ENTITIES,
  SET_ENTITY,
  UPDATE_SECTION_ITEMS,
  UPDATE_ENTITY,
  TRASH_ENTITY,
  REMOVE_ENTITY,
  UNTRASH_ENTITY,
} from '@constants/mutations'

import _isEqual from 'lodash/isEqual'
import _isEmpty from 'lodash/isEmpty'
import _pickBy from 'lodash/pickBy'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import {
  denormalizeSections,
  normalizeMenuSection,
} from '@/src/utils/normalizr'

export const state = {
  entities: {},
}

export const mutations = {
  [SET_ENTITIES](state, entities) {
    state.entities = entities
  },
  [MERGE_ENTITIES](state, updatedEntities) {
    state.entities = { ...state.entities, ...updatedEntities }
  },
  [UPDATE_SECTION_ITEMS](state, { sectionId, items }) {
    Vue.set(state.entities[sectionId], 'items_json', items)
  },
  [TRASH_ENTITY](state, sectionId) {
    Vue.set(state.entities, sectionId, {
      ...state.entities[sectionId],
      isTrashed: true,
    })
  },
  [UNTRASH_ENTITY](state, itemId) {
    let entity = state.entities[itemId]
    Vue.delete(entity, 'isTrashed')
    Vue.set(state.entities, itemId, entity)
  },
  [REMOVE_ENTITY](state, entityId) {
    Vue.delete(state.entities, entityId)
  },
  [UPDATE_ENTITY](state, { id, key, val }) {
    Vue.set(state.entities[id], key, val)
  },
  [SET_ENTITY](state, section) {
    Vue.set(state.entities, section.id, section)
  },
}

export const getters = {
  getCreateRoute: (state, getters, rootState, rootGetters) => (menuId) => {
    let getRouteFn = rootGetters['menus/getObjRouteFromEntity']
    let menusRoute = getRouteFn(ENTITY_NAMES.MENUS)
    let sectionsRoute = getRouteFn(ENTITY_NAMES.SECTIONS)
    return `${menusRoute}/${menuId}/${sectionsRoute}`
  }, // getCreateRoute

  getSections: (state) => (ids) =>
    !ids ? [] : ids.map((id) => state.entities[id]),

  getSection: (state) => (id) => state.entities[id] || null,

  getSectionMods: (state, getters, rootState, rootGetters) => (sectionId) => {
    let sectionMods = rootGetters['entities/getEntityMods'](
      ENTITY_NAMES.SECTIONS,
      sectionId
    )
    return sectionMods || {}
  }, // getSectionMods

  getModOrOrigSectionProp: (state, getters) => (
    sectionId,
    propKey,
    defaultValue = undefined
  ) => {
    let section = getters.getSection(sectionId)
    let sectionMods = getters.getSectionMods(sectionId)
    return _get(sectionMods, propKey)
      ? sectionMods.items
      : _get(section, propKey, defaultValue)
  }, // getModOrOrigSectionProp

  getSectionItemMods: (state, getters) => (sectionId) => {
    let sectionMods = getters.getSectionMods(sectionId)
    return sectionMods.itemMods || {}
  }, // getSectionItemMods

  getSectionSortOrder: (state, getters) => (sectionId) => {
    let section = getters.getSection(sectionId)
    let order = getters.getModOrOrigSectionProp(sectionId, 'items_order')
    return !_isEmpty(order) ? order : section.items
  }, // getSectionSortOrder

  getVisibleSectionItems: (state, getters, rootState, rootGetters) => (
    sectionId
  ) => {
    let myItemIds = getters.getModOrOrigSectionProp(
      sectionId,
      ENTITY_NAMES.ITEMS,
      []
    )
    let items = rootGetters['entities/getNonRemovedEntitySet'](
      ENTITY_NAMES.ITEMS,
      myItemIds
    )
    return items
  }, // getVisibleSectionItems

  getDirtySections: (state) =>
    Object.keys(state.entities).filter((id) => !!state.entities[id].isDirty),

  getSectionsToPublish: (state, getters) => {
    let dirtySections = getters.getDirtySections
    return dirtySections.length
      ? denormalizeSections(getters.getDirtySections)
      : false
  }, // getSectionsToPublish
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, getters }) {},

  removeSection({ dispatch }, { parentId, sectionId }) {
    dispatch(
      'entities/removeEntity',
      {
        entityName: ENTITY_NAMES.SECTIONS,
        entityId: sectionId,
        parentEntityName: ENTITY_NAMES.MENUS,
        parentId,
      },
      { root: true }
    )
  }, // removeSection

  updateSection({ commit }, { section, key, val }) {
    commit(UPDATE_ENTITY, { id: section.id, key, val })
  }, // updateSection

  updateSectionItems({ commit }, { sectionId, items }) {
    commit(UPDATE_ENTITY, { id: sectionId, key: 'items_json', val: items })
  }, // updateSectionItems

  addItem({ commit, getters }, { sectionId, item, index }) {
    let section = getters.getSection(sectionId)
    let updatedItems = [...section.items_json]
    updatedItems.splice(index, 0, item.id)
    commit(UPDATE_SECTION_ITEMS, { items: updatedItems, sectionId })
  }, // addItem

  removeItem({ commit, getters }, { sectionId, itemId }) {
    let section = getters.getSection(sectionId)
    let updatedItems = [...section.items_json]
    let index = updatedItems.indexOf(itemId)
    updatedItems.splice(index, 1)
    commit(UPDATE_SECTION_ITEMS, { items: updatedItems, sectionId })
  }, // removeItem

  trySectionMod({ dispatch }, { sectionId, key, val }) {
    let mods = { [key]: val }
    dispatch(
      'menus/tryMenuMod',
      {
        entityName: ENTITY_NAMES.SECTIONS,
        entityId: sectionId,
        mods,
      },
      { root: true }
    )
  }, // trySectionMod

  async updateSectionItemMods(
    { getters, dispatch, rootGetters },
    { sectionId, item, key, val }
  ) {
    // merge this item's current mods with the new key/val pair
    let currentSectionItemMods = getters.getSectionItemMods(sectionId)
    let itemMods = {
      ...currentSectionItemMods,
      [item.id]: { ...currentSectionItemMods[item.id], [key]: val },
    }

    // Only select the "real" mods (compared to the original content).
    // this way we dont mark unsaved changes for sections that don't actually
    // have real changes on em.
    itemMods = _pickBy(itemMods, (item, itemId) => {
      let originalContent = rootGetters['entities/getEntityOriginalContent'](
        ENTITY_NAMES.ITEMS,
        itemId
      )
      let realMods = _pickBy(item, (modVal, modKey) => {
        return !_isEqual(modVal, originalContent[modKey])
      })
      return !!Object.keys(realMods).length
    })

    // Update the section.item_mods
    await dispatch(
      'menus/tryMenuMod',
      {
        entityName: ENTITY_NAMES.SECTIONS,
        entityId: sectionId,
        mods: { itemMods },
      },
      { root: true }
    )
    return itemMods
  }, // updateSectionItemMods

  updateDirty({ commit }, { sectionId, isDirty }) {
    commit(UPDATE_ENTITY, { id: sectionId, key: 'isDirty', val: isDirty })
  }, // updateDirty

  async publishSectionChanges({ dispatch }) {
    let isValid = await dispatch('auth/validate', null, { root: true })
    if (isValid) {
      dispatch('publishSections')
    }
  }, // publishSectionChanges

  async publishSections({ getters, dispatch }) {
    let dirtySections = getters.getSectionsToPublish
    _forEach(dirtySections, async (section, sectionId) => {
      try {
        // Filter out unwanted section item fields for JSON storage
        let sectionItems = section.items_json.map((item) =>
          _omit(item, ['isNew', 'isDirty'])
        )
        let sectionWithSanitizedItems = { ...section, items_json: sectionItems }
        let updatedSectionRes = await api.updateSection(
          sectionWithSanitizedItems
        )
        let updatedSection = await dispatch(
          'processSectionResponse',
          updatedSectionRes
        )
        return updatedSection
      } catch (error) {
        console.warn('couldnt save the section')
        throw new Error(error)
      }
    })
  }, // publishSections

  async processSectionResponse({ dispatch, commit }, res) {
    let { entities, result } = await normalizeMenuSection(res.data)
    const section = entities.sections[result]

    await dispatch('entities/mergeAllEntities', entities, { root: true })
    return section
  }, // processSectionResponse
} // actions

// export const namespaced = true
