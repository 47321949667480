<template>
  <div class="editor">
    <EditorMenuBubble
      v-if="allowLinks"
      v-slot="{ commands, isActive, getMarkAttrs, menu }"
      class="menububble"
      :editor="editor"
      @hide="hideLinkMenu"
    >
      <div
        class="menububble"
        :class="{ 'is-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <form
          v-if="linkMenuIsActive"
          class="menububble__form"
          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
        >
          <input
            ref="linkInput"
            v-model="linkUrl"
            class="menububble__input"
            type="text"
            placeholder="https://"
            @keydown.esc="hideLinkMenu"
          />
          <button
            class="menububble__button"
            type="button"
            @click="setLinkUrl(commands.link, null)"
          >
            <VIcon color="white" small right>remove</VIcon>
          </button>
        </form>

        <template v-else>
          <button
            class="menububble__button"
            :class="{ 'is-active': isActive.link() }"
            @click="showLinkMenu(getMarkAttrs('link'))"
          >
            <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
            <VIcon color="white" small right>link</VIcon>
          </button>
        </template>
      </div>
    </EditorMenuBubble>
    <EditorContent
      v-toggle-select
      v-on-clickaway="deselect"
      :class="[
        'resto-editable',
        { 'r-edit': loggedIn },
        { 'is-dirty': isDirty },
        { hovering: showHover },
        { 'empty-text': isEmpty },
        { 'was-deleted': wasDeleted },
        { ph: placeholder },
        isMutliLine ? 'pm-ML' : 'pm-SL',
      ]"
      :editor="editor"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueClickaway from 'vue-clickaway'
import ToggleSelect from '@mixins/toggle-select'
import debounce from 'debounce'

// Import the editor
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Placeholder,
  HardBreak,
  History,
  BulletList,
  ListItem,
  Link,
} from 'tiptap-extensions'
import SingleLineDoc from '@tiptap/SingleLineDoc'
import SingleLineEditable from '@tiptap/SingleLineEditable'
import MultiLineDoc from '@tiptap/MultiLineDoc'
import MultiLineEditable from '@tiptap/MultiLineEditable'
import EnterKey from '@tiptap/extensions/EnterKey'
import _get from 'lodash/get'

export default {
  name: 'Editable',
  components: { EditorContent, EditorMenuBubble },
  mixins: [VueClickaway.mixin, ToggleSelect],
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    mods: {
      type: null,
      required: false,
      default: null,
    },
    content: {
      type: null,
      required: false,
      default: null,
    },
    placeholder: {
      type: [String, Boolean],
      required: false,
      default: 'Click to edit...',
    },
  },
  data() {
    return {
      hovering: false,
      editor: null,
      docType: null,
      isSingleLine: false,
      isMutliLine: false,
      allowLinks: false,
      linkUrl: null,
      linkMenuIsActive: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['loggedIn']),
    ...mapGetters('editor', ['isDragging']),
    isDirty() {
      if (this.mods === this.content) {
        return false
      }
      return typeof this.mods === 'string' || !!this.mods
    }, // isDirty
    editable() {
      return this.loggedIn && !this.isDragging
    }, // editable
    showHover() {
      return this.hovering && !this.selected
    },
    isEmpty() {
      return (
        (!this.content || !this.content.toString().length) &&
        (!this.mods || !this.mods.toString().length)
      )
    },
    wasDeleted() {
      return this.mods === ''
    },
    visibleContent() {
      // TODO (ESS) : maybe update this if we start accepting JSON obj's
      // to render tiptap modules. typeof may equal string or object
      return typeof this.mods === 'string' ? this.mods : this.content
    }, // visibleContent
  },
  watch: {
    isDirty(val) {
      this.$emit('dirtyUpdate', val)
    }, // isDirty
    visibleContent(visibleContent) {
      // let { mods, content } = this
      // console.log('[ 👀 Editable.visibleContent ]', { mods, content, visibleContent })
      if (this.mods !== visibleContent) {
        this.editor.setContent(visibleContent)
      }
    },
    editable(editable) {
      this.editor.setOptions({ editable })
    },
  },
  mounted() {
    this.setTipTapProps()
    let extSchema = this.getExtSchema()

    let extensions = [
      ...extSchema,
      new HardBreak(),
      new History(),
      new ListItem(),
      new BulletList(),
      new Placeholder({
        emptyEditorClass: 'is-editor-empty',
        emptyNodeClass: 'is-empty',
        emptyNodeText: !this.loggedIn ? null : this.placeholder,
        showOnlyWhenEditable: true,
        showOnlyCurrent: true,
      }),
      new EnterKey(),
    ]
    if (this.allowLinks) {
      extensions.push(new Link())
    }
    this.editor = new Editor({
      extensions,
      content: this.visibleContent,
      onUpdate: this.tiptapUpdate,
      editable: this.editable,
      onBlur: () => this.$emit('onBlur'),
      // useBuiltInExtensions: false,
    })
  },
  beforeDestroy() {
    this.$emit('editableDestroyed')
    this.editor.destroy()
  },
  methods: {
    setTipTapProps() {
      let tag = this.tag.split('.')[0]
      this.docType = ['span'].includes(tag)
        ? 'singleLineEditable'
        : 'multiLineEditable'
      this.isSingleLine = this.docType === 'singleLineEditable'
      this.isMutliLine = this.docType === 'multiLineEditable'
      this.allowLinks = this.isMutliLine
      this.contentNodePath = this.isSingleLine
        ? 'content[0]'
        : 'content[0].content[0]'
    }, // setTipTapProps

    getExtSchema() {
      return this.isSingleLine
        ? [new SingleLineDoc(), new SingleLineEditable(this.tag)]
        : [new MultiLineDoc(), new MultiLineEditable(this.tag)]
    }, // getExtSchema

    tiptapUpdate: debounce(function({ state, getHTML, getJSON, transaction }) {
      let json = getJSON()
      let editableNode = json.content.find((node) => node.type === this.docType)
      let content = null
      if (this.isMutliLine) {
        let multilineNodes = _get(editableNode, 'content[0].content')

        content = multilineNodes.map(this.processNode).join('')
      } else {
        content = _get(editableNode, `${this.contentNodePath}.text`, '')
      }

      this.update(content)
    }, 100), // tiptapUpdate

    processNode(node) {
      switch (node.type) {
        case 'hard_break':
          return '<br/>'
        case 'text':
          return this.processTextNode(node)
        default:
          return ''
      }
    },

    processTextNode(node) {
      let text = node.text
      let returnVal = text
      if (node.marks) {
        if (_get(node, 'marks[0].type') === 'link') {
          let href = _get(node, 'marks[0].attrs.href', '')
          returnVal = `<a href="${href}" target="_blank">${text}</a>`
        }
      }
      return returnVal
    }, // processTextNode
    update(content) {
      // console.log('[editable] : emit update ', { content })

      this.$emit('update', content)
    }, // update
    onPaste(e) {
      // Prevent the default pasting event and stop bubbling
      e.preventDefault()
      e.stopPropagation()
      // Get the clipboard data
      let paste = (e.clipboardData || window.clipboardData).getData('text')

      // Do something with paste like remove certain characters characters
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
      paste = paste.replace(/[^\P{L}\P{P}]/giu, '')
      // Find the cursor location or highlighted area
      const selection = window.getSelection()

      // Cancel the paste operation if the cursor or highlighted area isn't found
      if (!selection.rangeCount) return false

      this.replaceSelectedText(paste)
    }, // onPaste

    replaceSelectedText(replacementText) {
      var sel, range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.rangeCount) {
          range = sel.getRangeAt(0)

          // Polyfill: check for the insertText support
          if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, replacementText)
          } else {
            range.deleteContents()
            range.insertNode(document.createTextNode(replacementText))
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange()
        range.text = replacementText
      }
    }, // replaceSelectedText

    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
  }, // methods
}
</script>
<style lang="scss">
@import '@design';

.resto-editable {
  cursor: text;
  .ProseMirror:focus {
    outline: none;
  }
  &.ph:empty::before {
    display: block; /* For Firefox */
    font-style: italic;
    color: darken(white, 40%);
    pointer-events: none;
    content: attr(placeholder);
  }
}

.pm-ML .pm-el > p {
  margin-top: 0;
  margin-bottom: 0;
}

.is-dirty {
  position: relative;
  &::after {
    position: absolute;
    bottom: 0;
    left: -7px;
    width: 5px;
    height: 100%;
    // bottom:-5px;
    // left:0;
    // width:100%;
    // height:5px;
    content: '';
    background-color: $color-db-orange;
  }
}

.is-editor-empty:first-child::before {
  float: left;
  height: 0;
  font-style: italic;
  color: #aaa;
  pointer-events: none;
  content: attr(data-empty-text);
}

.menububble {
  position: absolute;
  display: -webkit-box;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
}
.menububble.is-active {
  opacity: 1;
  visibility: visible;
}
.menububble__button {
  display: -webkit-inline-box;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
}
.menububble__button:last-child {
  margin-right: 0;
}
.menububble__button:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.menububble__button.is-active {
  background-color: hsla(0, 0%, 100%, 0.2);
}
.menububble__form {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: #fff;
}
</style>
