import { Node } from 'tiptap'

export default class SingleLineEditable extends Node {
  constructor(selector) {
    super()
    let selectorParts = selector.split('.')
    this.tag = selectorParts[0] || 'h3'
    this.class =
      selectorParts.length > 1
        ? selectorParts.slice(-selectorParts.length + 1).join(' ')
        : ''
  }

  get name() {
    return 'singleLineEditable'
  }

  get node() {
    return new Node()
  }

  get schema() {
    return {
      content: 'text*',
      parseDOM: [
        {
          tag: this.tag,
        },
      ],
      toDOM: () => [this.tag, { class: this.class }, 0],
    }
  }
}
