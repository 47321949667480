import store from '@store/store'

let routes = [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@router/pageViewLoader')),
    props: (route) => ({ pageSlug: 'home' }),
  },

  { path: '/menus', name: 'menus', redirect: '/locations' },
  { path: '/menu', name: 'menu', redirect: '/locations' },
  {
    path: '/locations',
    name: 'locations',
    component: () => lazyLoadView(import('@views/page-locations')),
  },
  {
    path: '/locations/:locSlug',
    name: 'singleLocation',
    component: () => lazyLoadView(import('@router/locationViewLoader')),
    props: (route) => ({ locSlug: route.params.locSlug }),
  },
  {
    path: '/locations/:locSlug/layouts/:layoutSlug',
    name: 'layout',
    component: () => lazyLoadView(import('@router/layoutViewLoader')),
    props: (route) => ({
      locSlug: route.params.locSlug,
      layoutSlug: route.params.layoutSlug,
    }),
  },
  { path: '/admin', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/login')),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/:pageSlug',
    name: 'page',
    component: () => lazyLoadView(import('@router/pageViewLoader')),
    props: (route) => ({ pageSlug: route.params.pageSlug }),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

export default routes

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
