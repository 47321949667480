<script>
import { SITE_SLUG } from '@constants/siteDetails'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithSite',
  data() {
    return {
      isSiteLoading: false,
      siteSlug: SITE_SLUG,
    }
  },
  computed: {
    ...mapGetters('site', ['getSite']),
    theSite() {
      return this.getSite || {}
    }, // theSite
  },
  watch: {
    siteSlug: {
      async handler(val) {
        this.isSiteLoading = true
        await this.fetchSite({ siteSlug: this.siteSlug })
        this.isSiteLoading = false
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('site', ['fetchSite']),
  },
  render() {
    return this.$scopedSlots.default({
      theSite: this.getSite || false,
      isSiteLoading: this.isSiteLoading,
    })
  },
}
</script>
