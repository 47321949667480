<template>
  <BaseButton
    :text-color="outlineColor"
    :style="btnStyle"
    v-bind="allPropsAndAttrs"
    v-on="$listeners"
  >
    <slot>
      Click Here
    </slot>
  </BaseButton>
</template>

<script>
export default {
  name: 'ButtonOutline',
  props: {
    outlineColor: {
      type: String,
      default: '#FFF',
    },
    lineWeight: {
      type: String,
      default: '2px',
    },
  },
  data() {
    return {}
  },
  computed: {
    btnStyle() {
      return {
        border: `${this.lineWeight.replace('px', '')}px solid ${
          this.outlineColor
        } !important`,
        color: `${this.outlineColor} !important`,
      }
    }, // btnStyle
    allPropsAndAttrs() {
      let defaults = {
        color: 'transparent',
      }
      return {
        ...defaults,
        ...this.$props,
        ...this.$attrs,
      }
    }, // allPropsAndAttrs
  },
}
</script>

<style lang="scss" scoped>
@import '@design';

.v-btn {
  letter-spacing: 0.12em;
  // border:2px solid white !important;
}
</style>
