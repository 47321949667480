/**
 * For use with ENTITY_LOOKUP & SINGULAR
 * e.g. EntityMixin(ENTITY_TYPES.LAYOUTS) will
 * pass the Object key necessary to target the
 * correct lookup result
 */
export const ENTITY_TYPES = Object.freeze({
  LAYOUTS: 'Layout',
  MENUS: 'Menu',
  SECTIONS: 'MenuSection',
  ITEMS: 'MenuItem',
  ADDONS: 'MenuItemAddon',
  ATTRIBUTES: 'MenuItemAttr',
})

/**
 * For reference Vuex module names
 */
export const ENTITY_LOOKUP = Object.freeze({
  Layout: 'layouts',
  Menu: 'menus',
  MenuSection: 'sections',
  MenuItem: 'items',
  MenuItemAddon: 'addons',
  MenuItemAttr: 'attributes',
})

/**
 * For referencing things like component member properties
 * e.g. a MenuSection component will have a `section` prop
 * and this will allow for thepredictable referencing
 * of that property: this[ENTITY_LOOKUP_SINGULAR.MenuSection]
 */
export const ENTITY_LOOKUP_SINGULAR = Object.freeze({
  Layout: 'layout',
  Menu: 'menu',
  MenuSection: 'section',
  MenuItem: 'item',
  MenuItemAddon: 'addon',
  MenuItemAttr: 'attribute',
})

/**
 * For dynamic creation/reference of API routes
 */
export const ROUTE_LOOKUP = Object.freeze({
  Layout: 'layout',
  Menu: 'menus',
  MenuSection: 'menu-sections',
  MenuItem: 'items',
  MenuItemAddon: 'addons',
  MenuItemAttr: 'attributes',
})

export const ENTITY_NAMES = Object.freeze({
  LAYOUTS: 'layouts',
  MENUS: 'menus',
  SECTIONS: 'menu-sections',
  ITEMS: 'items',
  ADDONS: 'addons',
  ATTRIBUTES: 'attributes',
})

/**
 * For dynamic creation/reference of API routes
 * via an understanding of parent/child relationship
 * TODO (ESS): Maybe move this to Vuex on the module?
 */
export const ENTITY_PARENT_NAMES = Object.freeze({
  [ENTITY_NAMES.SECTIONS]: ENTITY_NAMES.MENUS,
  [ENTITY_NAMES.ITEMS]: ENTITY_NAMES.SECTIONS,
  [ENTITY_NAMES.ADDONS]: ENTITY_NAMES.ITEMS,
  [ENTITY_NAMES.ATTRIBUTES]: ENTITY_NAMES.ITEMS,
})
