<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: [String, Number],
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isAddonLoading: false,
    }
  },
  computed: {
    ...mapGetters('addons', ['getAddon']),
    addon() {
      return this.getAddon(this.id) || {}
    }, // addon
  },
  watch: {
    id: {
      async handler(id) {
        this.isAddonLoading = true
        await this.getAddon(id)
        this.isAddonLoading = false
      },
      immediate: true,
    },
  },
  methods: {},
  render() {
    let { addon, isAddonLoading } = this
    return this.$scopedSlots.default({
      addon,
      isAddonLoading,
    })
  },
}
</script>
