<script>
export default {
  name: 'WithLayoutElements',
  props: {
    regions: {
      type: Array,
      required: true,
      default: () => [],
    },
    regionName: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      areElementsLoading: false,
    }
  },
  computed: {
    elements() {
      return []
    }, // elements
  },
  render() {
    return this.$scopedSlots.default({
      areElementsLoading: this.areElementsLoading,
      elements: this.elements,
    })
  },
}
</script>
