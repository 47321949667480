var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drag-handle force-ems",style:(("font-size:" + _vm.defaultFontSize))},[(_vm.isSection && !_vm.isSummaryView)?_c('WithMenuSection',{attrs:{"id":_vm.relatedId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var section = ref.section;
    var isSectionLoading = ref.isSectionLoading;
    var isHovering = ref.isHovering;
    var myMods = ref.myMods;
    var itemMods = ref.itemMods;
    var itemsToSave = ref.itemsToSave;
return [(isSectionLoading)?_c('BaseLoader'):(!!section)?_c('BaseMenuComponent',{attrs:{"entity":section,"my-mods":myMods,"item-mods":itemMods,"items-to-save":itemsToSave,"name":"MenuSection"}}):_c('div',[_vm._v("Section Not Found")])]}}],null,false,645819359)}):_c('VCard',{staticClass:"pa-2 elevation-0 mb-2 align-items-center"},[(_vm.icon)?_c('VIcon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.text))]),(_vm.isSection)?_c('span',{staticClass:"ml-2"},[_vm._v("("+_vm._s(_vm.numItems)+")")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }