const TagClassMixin = {
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    tagClass: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    tagWithClasses() {
      let els = [this.tag]
      if (Array.isArray(this.tagClass)) {
        this.tagClass.map((cls) => cls.split(' ').map((c) => els.push(c)))
      } else if (this.tagClass.length) {
        els = [
          ...els,
          ...(this.tagClass.trim().length ? this.tagClass.split(' ') : []),
        ]
      }
      els.push('pm-el')

      return els.join('.')
    },
  }, // computed
}

export default TagClassMixin
