<template>
  <BaseButton
    :class="['btn--icon-mini ui', { 'btn--xs': xs }]"
    small
    :md-icon="mdIcon || 'add'"
    round
    mini
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    ><slot
  /></BaseButton>
</template>

<script>
import BaseButton from '@base/Button'
export default {
  name: 'ButtonIconMini',
  components: { BaseButton },
  props: {
    ...BaseButton.props,
    xs: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '@design';
$min-size: 25px;

.btn--icon-mini {
  width: $min-size;
  min-width: $min-size;
  height: $min-size;
  margin: 0 5px;
  &.btn--xs {
    $min-size: 18px;

    width: $min-size;
    min-width: $min-size;
    height: $min-size;
    .v-icon {
      font-size: 0.7rem;
    }
  }
}
</style>
