<template>
  <VBtn
    :aria-label="computedAriaLabel"
    :class="['btn-base', `${textColor}--text`]"
    :title="mdIcon === 'save' ? 'Save' : ''"
    v-bind="allPropsAndAttrs"
    :disabled="disabled"
    :depressed="allPropsAndAttrs.depressed"
    :outline="allPropsAndAttrs.outline"
    role="button"
    v-on="$listeners"
    @click="onClick"
  >
    <VIcon
      v-if="mdIcon.length && mdIconPosition === 'left'"
      :left="!hasIconAttr && !isFAB"
      :color="textColor"
      :small="allPropsAndAttrs.small"
      :large="allPropsAndAttrs.large"
      >{{ mdIcon }}</VIcon
    >
    <slot></slot>
    <VIcon
      v-if="mdIcon.length && mdIconPosition === 'right'"
      :color="textColor"
      :small="allPropsAndAttrs.small"
      :large="allPropsAndAttrs.large"
      :right="!hasIconAttr && !isFAB"
      >{{ mdIcon }}</VIcon
    >
  </VBtn>
</template>

<script>
import VBtn from '@vuetify/VBtn/'
export default {
  name: 'BaseButton',
  components: { VBtn },
  props: {
    ...VBtn.props,
    mdIcon: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
    mdIconPosition: {
      type: String,
      default: 'right',
    },
    color: {
      type: String,
      default: 'blue darken-2', // #940152
    },
    onClick: {
      type: Function,
      required: false,
      default: () => () => {},
    },
    textColor: {
      type: String,
      required: false,
      default: 'white',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    allPropsAndAttrs() {
      let defaults = {
        depressed: true,
      }
      return {
        ...this.$props,
        ...this.$attrs,
        ...defaults,
      }
    }, // allPropsAndAttrs
    hasIconAttr() {
      return typeof this.$attrs.icon !== 'undefined'
    }, // hasIconAttr
    isFAB() {
      return (
        typeof this.$attrs.fab !== 'undefined' ||
        typeof this.$attrs.mini !== 'undefined'
      )
    }, // isFAB
    computedAriaLabel() {
      return this.ariaLabel
        ? this.ariaLabel
        : this.$slots.default && typeof this.$slots.default[0].text === 'string'
        ? this.$slots.default[0].text.trim()
        : 'Button'
    }, // computedAriaLabel
  },
}
</script>

<style lang="scss">
@import '@design';

.btn-base {
  font-weight: bold;
  letter-spacing: 0.12em;
  &.v-btn {
    min-width: 40px;
  }
  &:not(.v-btn--round) {
    border-radius: 0;
  }
}
</style>
