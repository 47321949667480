import Vue from 'vue'
import { ENTITY_NAMES } from '@constants/lookupTables'
import {
  SET_ENTITIES,
  MERGE_ENTITIES,
  CREATE_ENTITY,
  TRASH_ENTITY,
  UNTRASH_ENTITY,
  REMOVE_ENTITY,
  UPDATE_ENTITY,
} from '@constants/mutations'
import { entityFactory } from '@utils/entityFactories'

import _get from 'lodash/get'

const entityName = ENTITY_NAMES.ITEMS
const rootTrue = { root: true }
export const state = {
  entities: {},
}

export const mutations = {
  [SET_ENTITIES](state, entities) {
    state.entities = entities
  },
  [MERGE_ENTITIES](state, updatedEntities) {
    state.entities = { ...state.entities, ...updatedEntities }
  },
  [CREATE_ENTITY](state, newEntity) {
    let newEntitySet = { ...state.entities }
    Vue.set(newEntitySet, newEntity.id, newEntity)
    Vue.set(state, 'entities', newEntitySet)
  },
  [TRASH_ENTITY](state, itemId) {
    Vue.set(state.entities, itemId, {
      ...state.entities[itemId],
      isTrashed: true,
    })
  },
  [UNTRASH_ENTITY](state, itemId) {
    let entity = state.entities[itemId]
    Vue.delete(entity, 'isTrashed')
    Vue.set(state.entities, itemId, entity)
  },
  [REMOVE_ENTITY](state, entityId) {
    Vue.delete(state.entities, entityId)
  },
  [UPDATE_ENTITY](state, { id, key, val }) {
    Vue.set(state.entities[id], key, val)
  },
}

export const getters = {
  getCreateRoute: (_, __, ___, rootGetters) => (sectionId) => {
    let getRouteFn = rootGetters['menus/getObjRouteFromEntity']
    let sectionsRoute = getRouteFn(ENTITY_NAMES.SECTIONS)
    let itemsRoute = getRouteFn(entityName)
    return `${sectionsRoute}/${sectionId}/${itemsRoute}`
  }, // getCreateRoute

  getItems: (state) => (ids) =>
    !ids ? [] : ids.map((id) => state.entities[id]),

  getItem: (state) => (id) => state.entities[id] || null,

  getItemMods: (_, __, ___, rootGetters) => (itemId) =>
    rootGetters['entities/getEntityMods'](entityName, itemId), // getItemMods

  getItemPrices: (_, getters) => (id) =>
    _get(getters.getItem(id), 'prices', []),

  getItemAddons: (state, getters) => (itemId) =>
    _get(getters.getItem(itemId), 'addons', []), // getItemAddons

  getNewAddon: (state, getters) => (itemId) =>
    entityFactory(ENTITY_NAMES.ADDONS, itemId), // getNewAddon
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, getters }) {},

  addItem({ commit, dispatch }, { sectionId, index }) {
    let item = entityFactory(entityName, sectionId)
    commit(CREATE_ENTITY, item)
    dispatch('sections/addItem', { item, sectionId, index }, rootTrue)
  }, // addItem

  removeItem({ commit, dispatch }, { sectionId, itemId }) {
    commit(TRASH_ENTITY, itemId)
    dispatch('sections/removeItem', { sectionId, itemId }, rootTrue)
  }, // removeItem

  trashItem({ commit, dispatch }, itemId) {
    commit(TRASH_ENTITY, itemId)
  }, // trashItem

  untrashItem({ commit, dispatch }, itemId) {
    commit(UNTRASH_ENTITY, itemId)
  }, // untrashItem

  updateItem({ commit }, { item, key, val }) {
    commit(UPDATE_ENTITY, { id: item.id, key, val })
  }, // updateItem
} // actions
