<script>
import { mapGetters } from 'vuex'
import fuzzyMatch from '@utils/fuzzyMatch'
export default {
  name: 'WithRelatedLayoutElements',
  props: {
    location: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      areElementsLoading: false,
    }
  },
  computed: {
    ...mapGetters('menus', ['getMenu']),
    ...mapGetters('sections', ['getSection']),
    ...mapGetters('items', ['getItem']),
    ...mapGetters('layoutEditor', ['elementSearch']),
    siteAndLocElements() {
      let elements = []
      elements.push({
        id: this.location.site.id,
        title: this.location.site.title,
        elType: 'Site Title',
        model: 'Site',
        content: { text: this.location.site.title },
      })
      elements.push({
        id: this.location.id,
        title: 'Hours',
        elType: 'Hours',
        model: 'Location',
        content: { text: this.location.hours_for_humans },
      })
      elements.push({
        id: this.location.contactinfo.id,
        title: 'Address',
        elType: 'Address',
        model: 'Contactinfo',
        content: { text: this.getAddress(this.location.contactinfo) },
      })
      elements.push({
        id: this.location.contactinfo.id,
        title: 'Phone',
        elType: 'Phone',
        model: 'Contactinfo',
        content: { text: this.location.contactinfo.phone_number },
      })
      return elements
    }, // siteAndLocElements

    menuElements() {
      let menuElems = []
      this.location.menus.forEach((menuId) => {
        let menu = this.getMenu(menuId)
        menuElems.push({
          id: menuId,
          title: menu.title,
          elType: 'Menu Title',
          model: 'Menu',
          content: { text: menu.title, data: menu },
        })
        menu.sections.forEach((sectionId) => {
          let section = this.getSection(sectionId)
          let elTitle = section.title
          if (
            !elTitle.length &&
            ['hide_title', 'condensed'].includes(section.section_type)
          ) {
            let firstItem = this.getItem(section.items_json[0])
            elTitle = firstItem.title
          }
          menuElems.push({
            id: section.id,
            title: elTitle,
            elType: section.is_callout ? 'Callout' : 'Section',
            model: 'MenuSection',
            content: { text: elTitle, data: section },
          })
        })
      })
      return menuElems
    }, // menuElements

    elements() {
      let elements = [...this.siteAndLocElements, ...this.menuElements]
      if (this.elementSearch) {
        let targetKey = (el) => el.title
        elements = fuzzyMatch(elements, targetKey, this.elementSearch)
      }
      return elements.map((e) => this.formatElement(e))
    }, // elements
  },
  methods: {
    formatElement({ title, model, id, elType, content }) {
      return {
        title,
        elType,
        related_model_type: model,
        related_model_id: id,
        type: 'related',
        content,
      }
    },
    getAddress(contactinfo) {
      return `${contactinfo.street_1}${
        contactinfo.street_2 ? ' ' + contactinfo.street_2 : ''
      } ${contactinfo.city}, ${contactinfo.state} ${contactinfo.zip}`
    },
  },
  render() {
    return this.$scopedSlots.default({
      elements: this.elements,
    })
  },
}
</script>
