import { ENTITY_NAMES } from '@constants/lookupTables'
import nanoid from 'nanoid'

const factories = {
  [ENTITY_NAMES.ITEMS]: (parentId) => ({
    id: nanoid(),
    title: 'New Item',
    description: '',
    prices: [],
    addons: [],
    attributes: [],
    is_special: 0,
  }),
  [ENTITY_NAMES.SECTIONS]: (parentId) => ({
    id: nanoid(),
    title: 'New Section',
    items: [],
    is_callout: 0,
    section_subtitle_note: 'New section note',
    section_type: null,
    order: 0,
    sub_menu_sections: [],
  }),
  [ENTITY_NAMES.ADDONS]: (parentId) => ({
    id: nanoid(),
    title: 'New Addon',
    prices: [],
  }),
}

export const entityFactory = (entityName, parentId) => {
  let newEntity = factories[entityName](parentId)
  return { ...newEntity, isNew: true }
}

export default {
  entityFactory,
}
